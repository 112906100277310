@import "src/styles/variables";

.approve-documents-view {
  position: relative;
  &__header {
    padding: 24px;
    background: #fff;
    .filename {
      h2 {
        display: flex;
        align-items: center;
        gap: 12px;
        color:#000;
        font-family: Noto Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
      }
    }

  }
  .approve-document-info {
    .details {
      margin-top: 12px;
      .label {
        color: $grey-base;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }
      .value {
        color: #000;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        .customer-image {
          width: 24px;
          height: 24px;
          border-radius: 24px;
          margin-right: 14px;
        }
        .info-icon{
          width: 13px;
          height: 13px;
          flex-shrink: 0;
          cursor: pointer;
        }
      }
    }
  }
  .document__container {
    *{
      -ms-overflow-style: thin !important;  
      scrollbar-width: thin !important;  
      &::-webkit-scrollbar {
        display: thin !important;
      }
    }
    width: 100%;
    height: 100%;
    background: $file-view-bg;
    padding: 30px 180px;
    .document-iframe {
      width: 100%;
      height: 80vh;
    }
    .spin-container{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .customer-details{
    width: 414px;
    height: 618px;
    flex-shrink: 0;
    background: var(--White-Base, #FFF);
    .customer-details-label{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label{
        margin-top: 24px;
        padding-left: 16px;
        color: var(--Black-Base, #000);
        /* P2 / 15 SB */
        font-family: "Noto Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 173.333% */
      }
      .value{
        margin-top: 24px;
      }
    }
    .customer-info-card-content {
      padding: 16px 20px;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
              margin: 0;
          }
          .title {
              color: $primary-grey;
          }
          .value {
              font-weight: 600;
          }
          .comment-value {
              font-weight: 600;
              text-align:justify;
              margin-left: 50px;
          }
      }
      div:not(:first-child) {
          margin-top: 20px;
      }
    }
  }
}
.skeleton__wrapper{
  padding: 70px 180px;
}

@media (max-width: 480px) {
  .approve-documents-view {
    margin-top: 78px;
  }
}