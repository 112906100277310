@import "src/styles/variables";

.promotionform {
    .ant-picker .ant-picker-range .picker-field{
        &.error{
            border: 1px solid $input-border-error-color !important;
        }
    }
}

.promo-config-container{
    .input-field{
        margin-top: 1rem;
    }
}

.checkboxcomponent {
    margin-top: 22px;
}

.installment-container{
    padding-left: 0.5rem;
    margin-top: 1.5rem;
}

.promotion-form-modal{
    padding: 1.25rem;
    font-family: "Noto Sans";
    font-style: normal;
    .audience-header{
        margin-bottom: 1.5rem;
        color: var(--Black-Base, #000);
        font-size: 18px;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }
    .subtitle{
        color: var(--Grey-Base, #75787B);
        font-size: 14px;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
    }
    .audience-container{
        display: flex;
        flex-direction: column;
        .checkboxcomponent{
            margin-top: 1rem;
        }
    }
    .asset-container{
        display: flex;
        flex-direction: column;
        .promotion-header {
            margin-bottom: 2rem;
            color: $yellow-dark-1;
            display: flex;
            align-items: center;
        
            .sample-text {
                margin-right: 4px;
            }
        
            .view-example {
                color: $blue-dark-1;
                cursor: pointer;
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 150% */
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-decoration-skip-ink: auto;
                text-decoration-thickness: auto;
                text-underline-offset: auto;
                text-underline-position: from-font;
                margin-left: 12px;
            }
        }
        
        .promotion-example-image {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 100;
            display: none;
            visibility: hidden;
            width: 850px;
            height: auto;
            flex-shrink: 0;
            margin-left: -87px;
        
            &.show {
                display: block;
                visibility: visible;
            }
        }
        
        h4{
            color: var(--Black-Base, #000);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; /* 162.5% */
        }
        .title{
            color: var(--Black-Base, #000);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 162.5% */
        }
        .subtitle{
            color: var(--Grey-Base, #75787B);
            font-family: "Noto Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            margin-bottom: 1rem;
        }
        .banner-container{
            display: flex;
            flex-direction: row;
            width: 70%;
            margin-bottom: 40px;
            .asset-1{
                display: flex;
                flex-direction: column;
                width: 45%;
                .header{
                    color: var(--Blue-Dark-1, #2461D8);
                    font-family: "Noto Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 171.429% */
                }
            }
            .asset-2{
                display: flex;
                flex-direction: column;
                width: 45%;
                .header{
                    color: var(--Blue-Dark-1, #2461D8);
                    font-family: "Noto Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 171.429% */
                    direction: rtl;
                    text-align: right;
                }
            }
        }
    }
}