@import "src/styles/variables";

.purchase-settlements-card {
  margin-top: 48px;
  margin-bottom: 24px;
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}

.settlement-id {
  color: rgb(41, 39, 81);
}

.refund-transaction-icon {
  max-width: 1em;
  max-height: 1em;
  margin-bottom: 0.2em;
}
