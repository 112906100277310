@import "src/styles/variables";

.spinner-component {
    text-align: center;
    padding-top: 2rem;
    .ant-spin-text {
        color: $primary-color;
    }
    .ant-spin-dot-item {
        background-color: $primary-color;
    }
}
