@import "src/styles/variables";

.badge-component {
    padding: 2px 8px;
    border-radius: 8px;
    width: fit-content;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    height: fit-content;
    text-transform: capitalize;
    color: #FFF;
    font-style: normal;
    font-family: Noto Sans;
    &.border{
        background-color: $badge-purchased-color;
        border: 1px solid $primary-blue;
    }
    &.active, &.completed, &.settled, &.refund_completed, &.paid, &.approved, &.kyc_approved, &.success {
        border-color: $badge-green-border;
        color: $primary-green;
        background-color: $badge-green-bg;
        &.fill{
            background-color: $primary-green;
        }
        &.promotion{
            background-color: $blue-dark-1;
        }
    }
    &.outstanding, &.pending_approval, &.pending_settlement, &.waiting_for_approval, &.outstanding_payment, &.pending, &.failed_verification, &.pending_verification, &.verification_in_progress, &.initiated {
        border-color: $badge-yellow-border;
        color: $yellow-dark-1;
        background-color: $badge-yellow-bg;
        &.fill{
            background-color: $yellow-base;
        }
    }
    &.partial_return, &.returned, &.return {
        border-color: $badge-orange-border;
        color: $badge-orange-text;
        background-color: $badge-orange-bg;
        &.fill{
            background-color: $badge-orange-text;
        }
    }
    &.inactive, &.return_cancelled, &.overdue, &.rejected, &.failure, &.failed, &.kyc_failed, &.user_cancelled, &.suspended, &.deleted {
        border-color: $badge-red-border;
        color: $primary-red;
        background-color: $badge-red-border;
        &.fill{
            background-color: $red-base;
        }
    }
    &.reapply{
        border-radius: 20px;
        border: 1px solid $light-yellow-2;
        background: var(--Gradient-Gradient-Orange, linear-gradient(101deg, $warm-yellow 3.17%, $dark-yellow 55.66%, $warm-red 102.94%));
    }
    &.fill{
        color: #fff;
        border: none;
    }
}