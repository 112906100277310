.auth {
    .landing-image__container {
        background: url("./../../assets/images/login_gradient.png") no-repeat;
        background-position: 50%;
        background-size: cover;
        height: 100vh;
        padding: 40px;
        overflow: hidden;
        .logo{
          width: 244px;
          height: 64px;
        }
        .content {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .bird {
            width: 394px;
            height: 341px;
          }
          .register{
            width: 524px;
            height: 499px;
          }
        }
      }
    h2 {
        font-weight: bold;
        margin-bottom: 16px;
    }
    .auth-bg {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .auth-form__container {
        padding: 15% 15% 0;
        margin-top: 80px;
        .input-field {
            margin-top: 32px;
        }
        .auth-form__btn {
            margin-top: 40px;
            height: 50px !important;
        }
    }
}

@media screen and (max-width: 1024px) {
    .auth {
        overflow-y: visible;
    }
}
