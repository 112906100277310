@import "src/styles/variables";

.approve-vendors-view {
    .approve-vendors-view-documents {
        height: 288px;
    }
    position: relative;
    .approve-vendors-view-controllers {
        position: absolute;
        display: flex;
        top: -6em;
        right: 0;
        column-gap: 16px;
    }
}
