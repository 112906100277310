@import "src/styles/variables";

.tooltip-component {
    width: 10px;
    height: 10px;
    margin-left: 2px;
    &.large{
        width: 20px;
        height: 20px;
        flex-shrink: 0;
    }
}


.refresh{
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 2px;
    cursor: pointer;
}