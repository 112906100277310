@import "src/styles/variables";

div.categories-config {
    width: 40%;
    margin: 0;
    .header{
        margin-top: 2em;
        .header-row{
            height: 45px;
        }
        h5.title{
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; 
            font-family: "Noto Sans";
            display: flex;
            align-items: center;
            margin: 0;
            .icon-component{
                margin-left: 8px;
            }
        }
    }
    .content{
        .category-section{
            overflow: scroll;
            height: 500px;
            margin-top: 10px;
            position: relative;
            .add-category-container{
                position: sticky;
                top: 1px;
                .add-btn{
                    cursor: pointer;
                    padding-left: 16px;
                    color: $blue-dark-1;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 26px; 
                    font-family: "Noto Sans";
                }
            }
            .card-component-content{
                position: relative;
                padding: 16px;
            }
            .input-field{
                margin-top: 1px;
                input{
                    height: 40px;
                }
            }
            .remove-icon{
                img{
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                }
            }
        }
    }
}