@import "src/styles/variables";

.purchase-payment-logs-card {
    margin-top: 48px;
    .title {
        font-size: 16px;
        font-weight: 600;
    }
    .installment{
        text-align: center;
    }
    .tooltip{
        text-align: center;
    }
}

.ant-tooltip.ant-tooltip-placement-right{
    .ant-tooltip-content{
        width: 234px;
        .ant-tooltip-inner{
            .container{
                display: flex !important;
                flex-direction: column !important;
                height: auto;
                border-radius: 12px;
                font-size: 13px;
                .content{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    padding-right: 4px;
                    .value{
                        text-align: left;
                    }
                }
            }
        }
    }
}