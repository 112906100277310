@import "./../../styles/variables";

.home {
    min-height: inherit;
    display: flex;
    background-color: #fff;
    .header{
        margin: 0 0 30px 0;
        .date-range-container{
            margin: 12px 0;
            display: flex;
            align-items: center;
            .icon-component{
                margin-right: 5px;
            }
            h3{
                font: normal normal 500 12px/24px Gilroy;
                letter-spacing: 1.2px;
                color:$primary-grey;
                text-transform: uppercase;
                margin: 0;
            }
        }
    }
    .home-right__container  {
        width: 87.5%;
        background-color: #fff;
    }
}

@media (max-width: 480px) {
    .home {
        .home-right__container  {
            width: 100%;
        }
    }
}