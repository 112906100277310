@import "src/styles/variables";

.pending-settlements {
    .partner__name {
        img {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            border: 0.5px solid $primary-border-color;
            margin-right: 8px;
        }
    }
    .dropdown-field.header-dropdown{
        margin-top: -7px;
        margin-left: 177px;
        width: 166px;
        height: 40px;
    }
}