@import "src/styles/variables";

.monthly-loan-limit-card {
  .monthly-loan-limit-value-card,
  .utilised-monthly-loan-limit-card {
    background: $button-default-border-color 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px $dashboard-box-shadow;
    border-radius: 20px;
  }
  .monthly-loan-limit-value-card {
    padding: 38px 34px;
    .title {
      font: normal normal 500 12px/24px Gilroy;
      letter-spacing: 1.2px;
      color: $secondary-grey;
      margin: 0;
    }
    .value {
      p {
        font: normal normal 800 24px/48px Gilroy;
        letter-spacing: 0px;
        color: $primary-black;
        margin: 0;
        span {
          font-size: 18px;
          margin-left: 2px;
        }
      }
    }
  }
  .utilised-monthly-loan-limit-card {
    padding: 26px 34px;
    .title {
      font: normal normal 400 13px/20px Gilroy;
      letter-spacing: 0px;
      color: $primary-grey;
    }
    .value {
      p {
        font: normal normal 800 20px/29px Gilroy;
        letter-spacing: 0.6px;
        color: $primary-black;
        margin-bottom: 17px;
        span {
          font: normal normal 400 20px/28px Gilroy;
          letter-spacing: 0.6px;
          color: $primary-black;
          margin-left: 16px;
        }
      }
    }
  }
}
