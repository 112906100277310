@import "src/styles/variables";

.customer-info-card {
    height: 285px;
    &:hover{
        .expand-details-icon{
            display: block;
        }
    }

    .customer-info-card-section {
        padding: 2.5em 4em;

        p {
            color: white;
            opacity: 0.6;
        }
        h2 {
            color: #fff;
            font-weight: 600;
        }
        .icon-edit {
            margin-left: 12px;
            .path1::before {
                color: transparent;
            }
            .path2::before {
                color: $primary-yellow;
            }
        }
    }

    .customer-info-card-content {
        height: 270px;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        .subheader{
            color: var(--Black-Base, #000);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; /* 162.5% */
            padding-bottom: 1rem;
        }
        .approve-customer-third-party-details-card-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
            &:last-child {
              margin-bottom: 0;
            }
            p {
              margin-bottom: 0;
            }
            .approve-customer-third-party-details-card-item-title {
              color: $primary-grey;
              min-width: 25%;
              font-size: 1rem;
              font-weight: 600;
              line-height: 26px;
            }
            .kyc-status{
              font-weight: 600;
            }
          }
        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                margin: 0;
            }
            .title {
                color: $primary-grey;
                min-width: 25%;
            }
            .value {
                font-weight: 600;
            }
            .comment-value {
                font-weight: 600;
                text-align:justify;
                margin-left: 50px;
            }
        }
    }

    .expand-details-icon{
        font-size: 18px;
        position: absolute;
        bottom: -9px;
        background-color: #eee;
        border-radius: 20px;
        font-weight: 100;
        color: $primary-grey;
        padding: 3px;
        left: calc(50% - 9px);
        display: none;
    }
}