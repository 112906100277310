@import "src/styles/variables";

.modal-component.large.vendor-settlement-form-modal{
    .vendor-settlement-form{
        .input-field, .date-picker-field{
            margin: 0;
        }
    }
    .modal-component-controllers{
        margin: 0;
    }
    .settlements-total__container,.settlements-amount__container{
        width: 100%;
        .details {
            display: flex;
            justify-content: space-between;
            color: $primary-black;
            &:not(:last-child){
                margin-bottom: 20px;
            }
            .label{
                font: normal normal 500 14px/20px Gilroy;
                letter-spacing: 0;
                span{
                    color: $primary-grey;
                }
            }
            .value {
                text-align: right;
                font: normal normal 600 14px/20px Gilroy;
            }
        }
    }
    .vendor-name-display-picture {
        display: flex;
        column-gap: 15px;
        align-items: center;
        margin-bottom: 32px;
        img {
            width: 56px;
            height: 56px;
            border-radius: 50%;
        }
        h4 {
            font: normal normal 700 20px/22px Gilroy;
            letter-spacing: -0.4px;
            margin-bottom: 4px;
        }
        p {
            margin-bottom: 0;
            font: normal normal 400 14px/20px Gilroy;
        }
    }
    .settlements-amount__container{
        .details{

            &:first-child{
                margin-bottom: 0;
            }
            .label,.value{
                font: normal normal 700 16px/23px Gilroy;
            }
        }
    }
}