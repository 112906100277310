@import "src/styles/variables";

.card-skeleton-component {
    min-height: 285px;
    border: 1.5px solid $card-border;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5em;
}
