@import "src/styles/variables";

.login-form {
    .login-form__forgot-password {
        margin-top: 40px;
        font-weight: 500;
        span {
            color: $primary-color !important;
            font-weight: 600;
            cursor: pointer;
        }
    }
}
