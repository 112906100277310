@import "src/styles/variables";

.search-input {
    height: 40px !important;
    padding: 0 16px !important;
    &.ant-input-affix-wrapper {
        width: 40%;
        border-radius: 12px;
        border: 1px solid $grey-light-4;
        background: #fff;
    }
    .ant-input {
        height: 38px;
        border-radius: 0;
    }
    .ant-input-prefix {
        margin-right: 16px;
    }
    i {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .search-input {
        &.ant-input-affix-wrapper {
            width: 100%;
        }
    }
}