@import "src/styles/variables";

.password-reset-link-sent {
    .password-reset-link-sent-proceed__btn {
        margin-top: 56px;
    }
    .mail__img {
        margin: 56px auto 0;
        display: block;
        width: 150px;
        height: 150px;
    }
}
