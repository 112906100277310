@import "src/styles/variables";

.vendor-personal-details-card {
    border-radius: 20px;
    padding: 24px;
    border: 1px solid $card-border;

    .vendor-personal-details-card-item {
        .label {
            letter-spacing: 1.2px;
            text-transform: uppercase;
            color: $blue-dark-1;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
            font-family: Noto Sans;
            .icon-star {
                .path1:before {
                    color: $primary-yellow;
                }
                .path2:before {
                    color: $primary-yellow;
                    margin-left: -1.1em;
                }
            }
        }
        .header {
            font-size: 18px;
            font-weight: 600;
            margin-top: 6px;
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */
            font-family: Noto Sans;
            &.name {
                font-weight: 700;
            }
            .edit-icon{
                cursor: pointer;
            }
            .update-alert{
                color: $red-base;
                font-family: Noto Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px; /* 155.556% */
            }
        }
        .details {
            margin-top: 12px;
            .details-label {
                width: 75px;
                display: inline-block;
                color: $grey-base;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
                font-family: Noto Sans;
            }
            .details-value {
                padding-left: 1em;
                word-break: break-all;
                color: #000;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px; /* 173.333% */
                font-family: Noto Sans;
            }
            .icon-component {
                margin-right: 15px;
                font-size: 14px;
            }
        }
        .image__container {
            margin-right: 12px;
            img {
              width: 50px;
              height: 50px;
              border-radius: 13px;
            }
            .icon-container{
              padding: 5px;
              border: 1px solid $card-border;
              width: 50px;
              height: 50px;
              border-radius: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                width: 22px;
                height: 22px;
                border-radius: 0;
              }
            }
        }
    }
}

.card-wide-skeleton-component {
    height: 206px !important;
}
