@import "src/styles/variables";
.vendor-instore-details-modal {
    width: 489px;
    .vendor-instore-details {
        .map-component {
            margin-top: 0;
        }
        .instore-address__wrapper {
            display: flex;
            align-items: baseline;
            column-gap: 12px;
            margin-bottom: 24px;
            .icon-map3{
                font-size: 17px;
            }
        }
        .vendor-instore-details-map {
            .map-container {
                height: 192px;
                margin-top: 0;

                .gmnoprint {
                    display: none;
                }
            }
        }
        .vendor-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            img {
                width: 32px;
                height: 32px;
                border-radius: 16px;
            }
            h2 {
                margin-bottom: 0;
                font-weight: 700;
                font: normal normal 800 24px/28px Gilroy;
            }
        }
        .instore-name {
            font-weight: 700;
        }
        
        .instore-address {
            margin-bottom: 0;
            font: normal normal 500 15px/21px Gilroy;
            letter-spacing: 0.45px;
            .icon-component {
                margin-right: 15px;
            }
            .icon-map {
                .path2::before,
                .path3::before {
                    color: #43389a;
                }
            }
        }
        .label {
            letter-spacing: 1.2px;
            color: $secondary-grey;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 24px;
        }
        .instore-contact-details {
            .name {
                font-size: 20px;
                font: normal normal 700 20px/28px Gilroy;
            }
            .contact {
                margin-top: 16px;
                display: flex;
                column-gap: 20px;
                font: normal normal 500 15px/21px Gilroy;
                letter-spacing: 0.45px;
            }
            p {
                margin-bottom: 0;
            }
            i {
                font-size: 16px;
                margin-right: 12px;
            }
        }
    }
    .vendor-instore-details-controllers {
        display: flex;
        justify-content: space-between;
    }
}
