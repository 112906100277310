@import "src/styles/variables";

.customer-details-card {
  height: 285px;
  .customer-details-card-section {
    position: relative;
    h2 {
      text-align: center;
      color: white;
      font-weight: 700;
      padding: 20px;
      margin-bottom: 0;
    }
    .display-picture {
      text-align: center;
      img {
        width: 100px;
        height: 100px;
        border: 2px solid $display-picture-border;
        border-radius: 60px;
        margin-top: -36px;
        object-fit: cover;
      }
      .status {
        background-color: $primary-green;
        padding: 5px 8px;
        color: white;
        font-size: 12px;
        font-weight: 500;
        width: fit-content;
        border-radius: 12px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 1;
        text-transform: capitalize;
        &.inactive {
          background-color: $primary-red;
        }
      }
    }
    .blacklist-customer {
      position: absolute;
      top: 32px;
      right: 32px;
    }
  }
}

.reapplied-mobile {
  border-radius: 24px 24px 0 0;
  border-top: 1px solid $border-yellow;
  border-right: 1px solid $border-yellow;
  border-left: 1px solid $border-yellow;
  background: linear-gradient(180deg, #FFF5E6 0%, #FFF 100%);
  border-bottom: none;
  .card-component-content{
    padding-bottom: 0;
  }
  .reapplication-container{
    display: flex;
    flex-direction: column;
    .reappy-header{
      display: flex;
      align-items: center;
      .title{
        background: linear-gradient(110deg, $warm-yellow 8.37%, $warm-yellow-2 30.15%, $warm-red 49.77%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 18px;
      }
    }
    .reapply-content{
      color: $grey-dark-5;
      font-family: "Noto Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding-top: 0.25rem;
      padding-bottom: 1rem;
    }
  }
}

.customer-details-card-content {
  border-radius: 20px;
  height: 288px;
  border: 1px solid $card-border;
  background: $purchase-card-bg;
  color: #fff;
  padding: 24px 32px;
  overflow: scroll;

  &.no-border{
    border-radius: 0 0 20px 20px;
  }

  .ant-divider {
    border-top-color: rgb(255, 255, 255, 0.1);
  }

  .item {
    .title {
      margin-bottom: 4px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      font-family: Noto Sans;
      opacity: 0.7;
      .refresh-icon{
        cursor: pointer;
        opacity: 1;
      }
    }
    .sub-title {
      .label {
        opacity: 0.7;
      }
      &-value {
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        color: #fff;
        opacity: 1;
        margin-right: 40px;
      }
      .icon-component {
        cursor: pointer;
      }
    }
    .value-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tooltip{
      cursor: pointer;
    }
    .value {
      color: #fff;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      font-family: Noto Sans;
      &.masked-value {
        visibility: hidden;
        position: relative;
      }
      
      &.masked-value::after {
        content: '***';
        visibility: visible;
        position: absolute;
        top: 4px;
        left: -3px;
      }
      .icon-edit-white {
        margin-left: 4px;
        cursor: pointer;
      }
    }
    .credit-status{
      margin-left: 4px;
      &.masked-value {
        visibility: hidden;
        position: relative;
      }
      
      &.masked-value::after {
        content: '***';
        visibility: visible;
        position: absolute;
        top: 0;
        left: -25px;
      }
    }
    .info{
      margin-left: 16px;
    }
  }
  .purchasing-status-progress {
    .ant-divider-horizontal {
      margin: 16px 0;
    }
    .status-header {
      .status {
        color: #fff;
        font-family: Noto Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 173.333% */
      }
      .close {
        font-size: 16px;
      }
    }
    .progress {
      .point {
        align-items: baseline;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &:last-child {
          align-items: baseline;
        }
        .icon {
          img {
            width: 16px;
            height: 16px;
          }
        }
        .text {
          color: #fff;
          font-family: Noto Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
        }
      }
    }
  }
  .credit-score-version-tag{
    margin-top: 40px;
    color: $text-badge-color;
    background-color: $badge-background-color;
    border-radius: 24px;
    width: 90%;
    font-size: 12px;
    line-height: 11px;
    padding: 5px 0 5px 10px;
    p{
      margin-bottom: 0;
      line-height: 17px;
    }
  }
  .credit-score-breakdown{
    .ant-divider-horizontal {
      margin: 16px 0;
    }
    .status-header {
      .status {
        color: #fff;
        font-family: Noto Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 173.333% */
      }
      .close {
        font-size: 16px;
      }
    }
    .breakdown-container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 16px;
      .masked-value {
        visibility: hidden;
        position: relative;
      }
      
      .masked-value::after {
        content: '***';
        visibility: visible;
        position: relative;
        top: 0;
      }
      .value{
        color: var(--White-Base, #FFF);
        text-align: right;

        /* P3/14 SB */
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
      }
      .label{
        text-transform: capitalize;
      }
    }
    .address-breakdown{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      font-family: "Noto Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
      opacity: 0.8;
      .label{
        flex: 0 0 72%;
      }
      .address-value{
        flex: 0 0 28%;
        text-align: right;
      }
      .masked-value {
        visibility: hidden;
        position: relative;
      }
      
      .masked-value::after {
        content: '***';
        visibility: visible;
        position: relative;
      }
    }
  }
}
