@import "src/styles/variables";

.shield-icon {
  width: 64px;
  height: 64px;
}

.two-factor-form-title-container {
  text-align: center;

  .title {
    color: var(--Black-Base, #000);
    text-align: center;
    /* H9 / 18 SB */
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }

  .sub-title {
    color: var(--Grey-Base, #75787B);
    text-align: center;
    /* P2 / 15 R */
    font-family: Noto Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 173.333% */
  }
}

.totp-instuctions {
  margin-top: 30px;
  color: var(--Black-Base, #000);
  font-family: Noto Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 173.333% */
}

.rotate-icon {
  max-width: 100%;
  max-height: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.qr-container {
  display: flex;
  margin-left: 20px;
  .qr-section {
    margin-right: 15px;
  }

  .rotate-section {
    width: 30px;
  }
}