@import "src/styles/variables";

.refund-details-card {
  margin-top: 40px;
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    gap: 12px;
    img{
        width: 24px;
    }
  }
  &__container {
    background: #ffffff;
    border-radius: 20px;
    .metrics{
        &-banner{
            padding: 24px;
            background: $refund-card-bg 0% 0% no-repeat padding-box;
            border-radius: 16px 16px 0 0;
            .metrics-column{
                .label{
                    h4{
                        color: $grey-base;
                        font-family: Noto Sans;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px; /* 150% */
                    }
                }
                .value{
                    p{
                        margin: 0;
                        color: #000;
                        font-family: Noto Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px; /* 171.429% */
                        img{
                            margin-left: 10px;
                            margin-bottom: 2px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .info{
        padding: 8px 24px 24px 24px;
        border: 1px solid $card-border;
        border-top: none;
        border-radius: 0 0 16px 16px;
        .left{
            border-right: 0.5px solid #a1a1a125;
        }
        &-column{
            display: flex;
            justify-content: space-between;
            h3{
                margin-top: 1em;
                margin-bottom: 0;
                color: $grey-base;
                font-family: Noto Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
            }
            p{
                margin-top: 1em;
                margin-bottom: 2px;
                color: #000;
                font-family: Noto Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
            }
            .upload-section{
                cursor: pointer;
                color: $primary-color;
                letter-spacing: 0.7px;
                font: normal normal 600 14px/20px Gilroy;
                .upload-icon{
                    width: 14px;
                    margin-right: 5px;
                    margin-bottom: 3px;
                }
                .inline-card-filename{
                    letter-spacing: 0.42px;
                    color: $primary-black;
                    font: normal normal 500 14px/20px Gilroy;
                }
            }
        }
    }
  }
}
