@import "src/styles/variables";

.viewondesktop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .desktop-icon{
        margin-left: 55px;
        width: 64px;
        height: 64px;
    }
    .content{
        margin-top: 24px;
        font: normal normal 700 16px/25px Gilroy !important;
    }
}