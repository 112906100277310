@import "src/styles/variables";

.deall-users {
    .deall-users-actions {
        visibility: hidden;
        .icon-trash {
            margin-left: 24px;
            .path1:before,
            .path2:before,
            .path3:before,
            .path4:before {
                color: $primary-red;
            }
        }
    }
    .ant-table-row {
        &:hover {
            .deall-users-actions {
                visibility: visible;
            }
        }
    }
}
