@import "src/styles/variables";

.pending-settlements {
    .vendor__name {
        img {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            border: 0.5px solid $primary-border-color;
            margin-right: 8px;
        }
    }
    .ant-picker{
        padding: 0 20px 0 18px;
    }
}