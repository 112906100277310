@import "variables";

/* Input Field */
.ant-input,
.ant-input-affix-wrapper {
  border-radius: $input-border-radius;
  border: 1.5px solid $input-border-color;
  background: #fff;
  padding: 0 24px;
  height: 50px;
  box-shadow: none !important;
  font-weight: 500;
  &:hover {
    border-color: $input-border-color;
  }
  &:focus {
    border-color: $input-border-color-focus;
  }
  .ant-input-prefix {
    margin-top: 2px;
  }
}
.ant-input-affix-wrapper {
  .ant-input {
    background: transparent;
  }
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  border-color: $input-border-color-focus !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $input-border-color;
}
// TODO handle add on before
// .ant-input-group-wrapper{
//   .ant-input-wrapper.ant-input-group{
//     border-radius: $input-border-radius;
//     // border-color: $input-border-color;
//     background: $input-bg;
//     font-weight: 500;
//     // border: 1px solid #d9d9d9;
//     .ant-input-group-addon{
//       // border-color: $input-border-color;
//       // border-radius: $input-border-radius 0 0 $input-border-radius;
//       padding: 0 0 0 18px;
//     }
//     .ant-input{
//       border-left: 0;
//     }
//   }
// }

/* Button */

.ant-btn {
  /* Primary button */
  background-color: $button-default-bg-color;
  color: $button-default-text-color;
  border-radius: $button-default-border-radius;
  border: none;
  letter-spacing: $button-default-letter-spacing;
  transition: none;
  line-height: 1;
  &.ant-btn-primary {
    background: linear-gradient(90deg,#1da9d8,#1c9ed5 15%,#1c81cf 40%,#1c53c5 74%,#1c2bbc);
    color: $button-primary-text-color;
    &:hover,&:active {
      outline: 2px solid $button-primary-border-hover-color;
      background: linear-gradient(90deg,#1da9d8,#1c9ed5 15%,#1c81cf 40%,#1c53c5 74%,#1c2bbc);
    }
  }
  &.ant-btn-primary[disabled],
  &.ant-btn-primary[disabled]:hover,
  &.ant-btn-primary[disabled]:focus,
  &.ant-btn-primary[disabled]:active {
    color: rgb(255 255 255 / 72%);
  }
  .ant-btn-loading-icon .anticon {
    padding-right: 2em;
  }
}

/* Checkbox */
.ant-checkbox-wrapper {
  &:hover {
    .ant-checkbox-inner {
      border-color: $checkbox-hover-border-color;
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $checkbox-bg-color;
      border-color: $checkbox-hover-border-color !important;
    }

    &::after {
      border-color: $checkbox-hover-border-color;
    }
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary-color;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after{
    background-color: $primary-color;
  }
}

/* Radio Group */
.ant-radio {
  .ant-radio-inner {
    border-color: $radio-border-color !important;
  }

  &.ant-radio-checked {
    .ant-radio-inner {
      border-color: $radio-checked-border-color !important;
      &::after {
        background-color: $radio-checked-bg-color;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
      }
    }
  }
}

/* Radio solid */
.ant-radio-wrapper {
  margin-right: 24px;
}
.ant-radio-group.ant-radio-group-solid {
  .ant-radio-button-wrapper {
    &:hover {
      color: $checkbox-hover-text-color;
    }
    &.ant-radio-button-wrapper-checked {
      background-color: $radio-checked-bg-color;
      border-color: $radio-checked-border-color !important;
      color: $checkbox-text-color;

      &::before {
        background-color: $radio-checked-bg-color !important;
      }
    }
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 8px !important;
}

/* Select */
.ant-input-group-addon{
  padding: 0;
  background: none;
  border: none;
  .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
      border-radius: 16px 0px 0 16px;
      padding: 0 20px 0 5px;
      outline: none;
      background-color: $input-bg;
    border-color: $select-border-color;
      .ant-select-selection-item{
          padding: 0;
          width: 100px;
          color: rgba(0, 0, 0, 0.86);
      }
  }
}
.ant-select.ant-select-single.ant-select-show-arrow,
.ant-select.ant-select-multiple.ant-select-show-search{
  width: 100%;

  .ant-select-selector {
    box-shadow: none;
    height: 50px;
    padding: 0 32px 0 24px;
    border-radius: $input-border-radius;
    border: 1.5px solid $input-border-color;
    background: #fff;
    &:hover {
      border-color: $input-border-color;
    }
    .ant-select-selection-placeholder{
      letter-spacing: 0;
      color: $grey-light-2;
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 47.5px;
    }
  }

  &.ant-select-open {
    .ant-select-selector {
      border: 1px solid $input-border-color-focus;
    }
  }
  .ant-select-arrow {
    right: 20px;
  }
}
.ant-select.ant-select-multiple.ant-select-show-search{
  .ant-select-selector {
    padding: 0 16px;
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 20px;
    }
    .ant-select-selection-placeholder{
      padding: 0 12px;
    }
  }
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow: none;
}
.ant-notification {
  .ant-notification-notice {
    border-radius: 0 !important;
    line-height: 1.5 !important;
  }
  .ant-notification-notice-success {
    border-left: 4px solid $success-notification-color !important;
    .icon-info {
      margin-top: -1em;
      .path1::before,
      .path2::before {
        color: $success-color !important;
        opacity: 1 !important;
      }
      .path3::before,
      .path4::before {
        color: #fff !important;
      }
    }
  }
  .ant-notification-notice-icon {
    margin-left: 0 !important;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1px;
  }
  .ant-notification-notice-description {
    font-weight: 600 !important;
    margin-left: 40px !important;
  }
  .ant-notification-notice-close {
    top: 50%;
    transform: translateY(-45%);
  }
  .ant-notification-notice-message:empty {
    display: none;
  }
}
.ant-select-dropdown {
  background: #ffffff;
  box-shadow: 0 10px 80px $select-box-shadow;
  border: 1px solid $primary-border-color;
  border-radius: 14px;
  .ant-select-item {
    background: none !important;
  }
  .ant-select-item-option-active {
    font-weight: 500;
  }
  .ant-select-item-option-selected {
    font-weight: 600 !important;
  }
}

/*Tabs*/
.ant-tabs {
  line-height: 1;
  .ant-tabs-nav {
    &::before {
      border: none !important;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          font-weight: 500;
          padding: 0 0 10px;
          font-size: 15px;
          &:hover {
            color: $blue-dark-1;
          }
        }
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: $blue-dark-1;
            font-weight: 600;
            text-shadow: none;
          }
        }
      }
    }
    .ant-tabs-ink-bar {
      background: $blue-dark-1;
      width: 30px !important;
    }
  }
}

/* Table */
.ant-table {
  line-height: 1;
  .anticon.anticon-caret-up.ant-table-column-sorter-up.active,
  .anticon.anticon-caret-down.ant-table-column-sorter-down.active{
    color: $blue-dark-1;
  }
  .ant-table-thead {
    border-radius: 10px;
    height: 40px;
    th {
      padding: 0 16px;
      text-transform: uppercase;
      border-bottom: none;
      font-size: 12px;
      letter-spacing: 1.2px;
      color: $secondary-grey;
      font-weight: 500;
    }

    th:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .ant-table-thead > tr > th {
    background: $table-header;
  }
  .ant-table-tbody {
    margin-top: 5px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ant-table-container table > tbody > tr td:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .ant-table-container table > tbody > tr td:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
    padding: 0 16px;
    font-weight: 500;
  }
  .ant-table-tbody > tr {
    height: 40px;
    cursor: pointer;  
    .table-edit-action {
      visibility: hidden !important;
  }
    &:hover {
        .table-edit-action  {
            visibility: visible !important;
            color: var(--Blue-Dark-1, $blue-dark-1);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
        }
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #faf6e8;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td{
    background: #faf6e8;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 2px;
    tbody:before {
      content: "@";
      display: block;
      line-height: 5px;
      visibility: hidden;
    }
  }
  .ant-table-column-sort {
    .ant-table-column-title {
      color: $primary-blue;
    }
  }
  td.ant-table-column-sort {
    background: unset;
  }
  .ant-table-column-has-sorters{
    .ant-table-column-sorters{
      justify-content: flex-start !important;
    }
    .ant-table-column-title {
      flex: initial !important;
    }
  }
}
/*Date Picker*/
.ant-picker {
  border-radius: $input-border-radius;
  border: 1.5px solid $input-border-color;
  background: #fff;
  height: 50px;
  padding: 0 20px 0 24px;
  .ant-picker-input {
    height: 100%;
  }
  &:hover {
    border-color: $input-border-color-focus;
  }
  &.ant-picker-focused {
    border-color: $input-border-color-focus;
    box-shadow: none;
  }
}

// BADGE
.ant-badge-count{
  background-color: $primary-red;
  min-width: 16px;
  height: 16px;
  padding: 0;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.6;
  border: 1px solid #FEFEFE;
  .ant-scroll-number-only{
      .ant-scroll-number-only-unit.current{
          font: normal normal 600 10px/10px Gilroy !important;
      }
  }
}

.ant-tooltip{
  .ant-tooltip-arrow-content::before, .ant-tooltip-inner{
    background: $tooltip-background-color;
    border-radius: 8px;
    font: normal normal 500 13px/20px Gilroy;
    letter-spacing: 0.39px;
    color: #FFFFFF;
  }
}

.antd-whole-tab {
  display: inline-flex;
  &.two-tone {
    .ant-tabs-nav {
      background: #ececec;
    }
    .ant-tabs-tab {
      color: rgba(0, 0, 0, 0.199);
    }
    .ant-tabs-nav-list {
      > div:nth-child(2) {
        &.ant-tabs-tab.ant-tabs-tab-active {
          background-color: $tab-background-color;
        }
      }
    }
  }
  .ant-tabs-nav {
    &::before {
      display: none;
    }
    margin: 0;
    height: 31px;
    max-width: 100%;
    background: #fff;
    border: 1px solid;
    box-shadow: 0px 0px 40px #43389A0F;
    border: 1px solid #DBD7FA;
    border-radius: 16px;
    width: max-content;
    &-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          margin: 0;
          padding: 0px 16px !important;
          border-radius: 14px;
        }
      }
    }
    .ant-tabs-tab,
    .ant-tabs-tab-btn {
      font: normal normal 500 14px/20px Gilroy;
      letter-spacing: 0px;
      color: $tab-background-color;
      &:hover,
      &:focus,
      &:active,
      &:focus-visible,
      &:focus-within {
        color: $tab-background-color;
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      margin: 1px;
      * {
        color: #fff !important;
        font-weight: 600;
      }
      background-color: $checkbox-bg-color;
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      display: none;
    }
  }
}

// Divider
.ant-divider-vertical{
  height: 11.6em;
}

.radio-row {
  .ant-radio-wrapper {
    display: flex;
  }
  .ant-radio-wrapper:not(:first-child) {
    margin-top: 30px;
  }
}