@import "src/styles/variables";

.purchase-outstanding-payments-card {
  margin-top: 32px;
  padding: 28px 24px;
  border-radius: 24px;
  border: 1px solid $card-border;
  background: #fff;

  .payment__container {
    background: #ffffff;
    box-shadow: 0 0 40px $dashboard-box-shadow;
    border-radius: 20px;
    margin-top: 16px;
    padding: 24px 32px;
    .item {
      .label {
        color: $primary-grey;
        font-size: 13px;
      }
      .value {
        font-size: 16px;
        font-weight: 700;
        margin-top: 12px;
      }
    }
  }
  .due-on__container {
    display: flex;
    column-gap: 10.65px;
    .badge-component {
      margin-top: -3px;
    }
  }
}
