@import "src/styles/variables";

.payment-kpi-component {
  .content {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0 0 40px rgba(67, 56, 154, 0.06);
    border-radius: 26px;
    padding: 30px 40px 14px 40px;
    .total-installments-value {
      margin-top: 36px;
    }
    .late-installments-split,
    .default-installments-split {
      margin-top: 16px;
      .text {
        width: 88%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
          font: normal normal 400 12px/20px Gilroy;
          letter-spacing: 0;
          color: #29274e;
          &:last-child{
            margin-bottom: 0;
          }
        }
        .value {
          font: normal normal 600 12px/20px Gilroy;
          letter-spacing: 0;
          color: $primary-black;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    .header {
      font: normal normal 500 12px/24px Gilroy;
      letter-spacing: 1.2px;
      color: $primary-grey;
      margin-bottom: 8px;
    }
    .header-value {
      font: normal normal 700 20px/29px Gilroy;
      letter-spacing: 0.6px;
      color: $primary-black;
      margin-bottom: 0;
    }
    .header-value-info {
      font: normal normal 500 13px/19px Gilroy;
      letter-spacing: 0.39px;
      color: $primary-black;
    }
  }
}
