@import "src/styles/variables";

.approve-vendors {

    .list-vendors-business__name {
        cursor: pointer;
        img {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            border: 0.5px solid $primary-border-color;
            margin-right: 8px;
        }
    }
}