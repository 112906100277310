@import "src/styles/variables";

.creditscoreconfiguration {
    margin-bottom: 10px;
    .add-new-score,
    .send-data{
        color: var(--Blue-Dark-1, $blue-dark-1);
        text-align: right;
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        cursor: pointer;
        margin-right: 2px;
        margin-left: 2px;
    }
    .threshold-card{
        margin-bottom: 16px;
       .card-component-content{
            display: flex;
            justify-content: flex-start;
        }
        .approval-threshold, .phone-threshold{
            flex: 0 0 25%;
            .label{
                color: var(--Grey-Base, $grey-base);
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                text-transform: uppercase;
            }
            .value{
                color: var(--Black-Base, $basic-black);
                font-family: "Noto Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
            }
        }
        .approval-threshold{
            flex: 0 0 25%;
        }
    }
}