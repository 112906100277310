@import "src/styles/variables";

.notifications {
    display: inline;
    .icon-bell {
        [class^="path"] {
            &::before {
                color: $checkbox-bg-color;
            }
        }
    }
}
.notifications-list {
    background: #ffffff;
    box-shadow: 0px 0px 40px #0f0c2c29;
    border-radius: 20px;
    width: 343px;
    height: 80vh;
    overflow-y: scroll;
    padding: 24px 0 0 24px;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .notification-item {
        display: flex;
        column-gap: 14px;
        align-items: center;
        cursor: pointer;
        .notification-icon {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 18px;
                height: 22px;
            }
        }
        .notification-details {
            .title {
                font-weight: 600;
                margin-bottom: 10px;
                line-height: 1;
            }
            .date {
                font-size: 12px;
                color: $primary-grey;
                line-height: 1;
            }
        }
        &.read {
            .notification-icon {
                background: #e4e9f2;
                opacity: 0.5;
            }
        }
        &.unread {
            .notification-icon {
                background: #f2f1fa;
            }
        }
    }
}
