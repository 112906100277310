@import "src/styles/variables";

.calcualtion-modal-content{
  h3{
    color: #000;
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
  }
  .purchase-summary-card{
    .label, .value {
      h3{
        color: #000;
      }
    }
    .label, .value {
      h5{
        color: $grey-base;
      }
    }
    background-color: #fff;
    height: 100%;
    padding: 0;
    .purchase-summary-list__container{
      height: 100%;
      .purchase-summary-sub-total__container{
          &:not(:last-child) {
            margin-bottom: 32px;
          }
          .label{
            h5{
              width: 98%;
            }
          }
      }
    }
  }
  .purchase-summary-total__container {
    display: flex;
    justify-content: space-between;
    padding-right: 16px;

    .label,
    .value {
      font-family: Noto Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 173.333% */
      color: #000;
    }
  }
  .adjust-info{
    margin-top: 24px;
    .content{
      background-color: #fbf6e9;
      padding: 15px;
      border-radius: 14px;
      p{
        margin: 0;
        display: flex;
        align-items: center;
        color: #000;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        b{
          font-family: Noto Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 171.429% */
          color: #000;
        }
        .icon-info {
          margin-right: 10px; 
          .path1::before,
          .path2::before,
          .path3::before,
          .path4::before {
              color: $primary-yellow !important;
              font-size: 20px;
          }
        }
      }
    }
  }
}
