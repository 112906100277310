@import "src/styles/variables";

.purchases-view__container {
  .purchase-card-title {
    img {
      width: 20px;
      height: 20px;
    }
    .text {
      font-size: 16px;
      color: #000;
      font-family: Noto Sans;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 162.5% */
    }
  }
  .purchase-card-table{
      border-radius: 24px;
      border: 1px solid $card-border;
      background: #fff;
      padding: 28px 24px;
    .ant-divider.ant-divider-horizontal.table-component-divider {
      display: none;
    }
    .ant-table {
      .ant-table-thead > tr > th {
        background-color: #fff;
      }
      .ant-table-thead {
        height: 35px;
        tr th {
          padding: 0;
          color: $grey-base;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          font-family: Noto Sans;
        }
      }
      .ant-table-tbody {
        margin-top: 0;
        &::before {
          display: none;
        }
        tr td {
          padding: 0;
          color: #000;
          line-height: 24px; /* 171.429% */
          font-family: Noto Sans;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
        background: #fff;
        cursor: default;
      }
    }
  }
}
