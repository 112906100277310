@import "src/styles/variables";

.latefeeconfig {
    margin: 2em 0;
    .header{
        color: var(--Black-Base, #000);
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 173.333% */
    }
    .label{
        color: var(--Grey-Base, $grey-base);
        font-family: "Noto Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
    }
    .value{
        color: var(--Black-Base, $basic-black);
        font-family: "Noto Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }
}