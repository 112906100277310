@import "src/styles/variables";

.dashboard-filter-menu {
    .ant-dropdown-trigger.ant-dropdown-link{
        margin: 0;
        display: flex;
        align-items: flex-start;
        font: normal normal medium 14px/20px Gilroy;
        letter-spacing: 0;
        color: #292751;
    }
    .icon-component {
        font-size: 15px;
        margin-right: 6px;
        .icon-dropdown-arrow{
            margin-left: 5px;
        }
    }
}

.ant-dropdown-menu-title-content, .ant-dropdown-menu-submenu-title{
    color: $dashboard-title-color;
    line-height: 20px;
}