@import "src/styles/variables";

.radio-component {
    .label {
        display: block;
    }
    .radio-sub-text{
        color: var(--Grey-Base, #75787B);
        /* P3/14 R */
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        left: 23px;
        position: relative;
        top: 5px;
    }
}