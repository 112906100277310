@import "src/styles/variables";

.promotion-details {
    background: #222B4A;
    min-height: 14.5rem;
    max-height: 20rem;
    color: var(--White-Base, #FFF);
    font-family: "Noto Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem; /* 160% */
    .processin-fee{
        .label{
            font-size: 14px;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
            opacity: 0.7;
        }
        .value{
        }
    }
    .installment-container{
        display: flex;
        flex-direction: column;
        .installments{
            display: flex;
            justify-content: space-between;
            &.header{
                font-size: 14px;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
                opacity: 0.7;
                margin: 0;
            }
            .label{
                flex: 0 0 30%;
            }
            .value{
                flex: 0 0 70%;
            }
        }
    }
}

.promotion-info {
    min-height: 14.5rem;
    max-height: 20rem;
    .container{
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        .label{
            font-size: 14px;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
            opacity: 0.7;
        }
        .value{
            color: var(--Black-Base, #000);
            font-size: 14px;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
            flex: 0 0 50%;
        }   
    }
}

.asset-info {
    display: flex;
    flex-direction: row;
    h4{
        color: var(--Black-Base, #000);
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 162.5% */
    }
    .banner-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 400px;
        margin-bottom: 40px;
        .asset-1{
            display: flex;
            flex-direction: column;
            .header{
                color: var(--Grey-Base, #75787B);
                font-family: "Noto Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
            }
            .banner{
                height: 260.16px;
                width: 192px;
                border-radius: 1rem;
                &.explainer{
                    height: 177.6px;
                }
                &.small{
                    height: 54.154px;
                }
            }
        }
        .asset-2{
            display: flex;
            flex-direction: column;
            .header{
                color: var(--Grey-Base, #75787B);
                font-family: "Noto Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
                direction: rtl;
            }
            .banner{
                height: 260.16px;
                width: 192px;
                border-radius: 1rem;
                &.explainer{
                    height: 177.6px;
                }
                &.small{
                    height: 54.154px;
                }
            }
        }
    }
}