@import "src/styles/variables";

.quick-show-docs{
    position: fixed;
    z-index: 1;
    width: 93px;
    height: 58px;
    top: 92px;
    right: 0px;
    border-radius: 16px 0px 0px 16px;
    border-bottom: 1px solid var(--Grey-Light-4, #E6E7E7);
    background: var(--White-Base, #FFF);
    box-shadow: 0px 2px 40px 0px rgba(1, 6, 103, 0.08);
    .image-style{
        width: 84px;
        height: 52px;
    }
}

.show-doc{
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    .image-style{
        position: relative;
        left: -40px;
        width: 373px;
        height: 203px;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  
    .close-icon {
      position: absolute;
      bottom: -20%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10000;
  
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  
  .preview-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    position: relative;
  
    .image-style {
      width: 373px;
      height: 203px;
      margin-bottom: 16px;
    }
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  

.customer-edit-view {
    padding-bottom: 70px;
    .customer-form-details__container{
        padding: 0;
        .section-header{
            color: var(--Black-Base, #000);
            font-family: "Noto Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */
            margin-top: 8px;
            margin-bottom: 4px;
        }
    }
}
.approve-customers-control{
    position: absolute;
    display: flex;
    top: -2em;
    right: 0;
    column-gap: 16px;
    padding: 14px 20px;
    .button-component{
        button.ant-btn{
            width: 100% !important;
        }
        flex-basis: 50%;
    }

    &-row{
        width: 100%;
        .right-button{
            min-width: 22px;
        }
        .label{
            color: var(--Grey-Base, $grey-base);
            text-align: right;
            font-family: "Noto Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 18px; /* 150% */
        }
        .value{
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            &.approve {
                color: var(--Green-Base, $success-color);
              }
            &.decline {
                color: var(--Red-Base, $red-base);
            }
        }
    }

    .approve-customer {
        position: absolute;
        box-sizing: border-box;
        left: -140%;
    }
    .ant-dropdown-menu {
        position: absolute;
        right: -19px;
        top: 35px;
    }

    .verify-text {
        display: inline-block;
        padding-right: 5px;
    }
    .ant-dropdown-menu-title-content {
        font-weight: 600;
    }
}

.checkboxcomponent{
    &.iscore-checkbox{
        .ant-checkbox-wrapper {
            font-weight: 400 !important;
        }
    }
}

@media (max-width: 480px) {
    .approve-customers-control{
        position: fixed;
        border: 1px solid $border-color;
        background-color: $button-default-bg-color;
        padding: 14px 0;
        margin-top: 5px;
        top: auto;
        bottom: 0;
        left: 0;
        z-index: 1;
        column-gap: 16px;
        text-align: center;
        justify-content: center;
        .ant-btn.ant-btn-primary,
        .button-component.negative button {
            width: 120px !important;
        }

        .ant-dropdown-menu {
            top: -55px;
        }
        padding: 14px 4px;
    }
}