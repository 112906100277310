@import "src/styles/variables";

.create-refund-confirmation-modal {
  .alert-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img {
      width: 100px;
      margin-bottom: 30px;
    }
    h3 {
      color: #000;
      font-family: Noto Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 160% */
    }
    p {
      color: $grey-base;
      font-family: Noto Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 173.333% */
      text-align: center;
    }
  }
  .refund-data {
    margin-top: 6px;
    border: 0.7px solid $card-border;
    border-left: 0;
    border-right: 0;
    &-value {
      margin: 0.7em 0;
      color: #000;
      font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      &.last {
        text-align: end;
      }
    }
  }
  .checkbox-action {
    margin-top: 25px;
    &-title {
      margin: 0;
      color: #000;
      font-family: Noto Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 173.333% */
    }
  }
}
