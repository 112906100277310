/* Color palette */
$primary-color: #4537a0;
$secondary-color: #006fba;
$light-white: #ffffff40;
$primary-light-color: #0d494e10;
$primary-black: #292751;
$basic-black: #000000;
$dark-blue: #22509d;
$primary-grey: #7886a9;
$secondary-grey: #7a87ab;
$light-grey: #00000015;
$dark-grey: #8898aa;
$success-color: #19aa7a;
$danger-color: #fe0000;
$warning-color: #f3b21b;
$body-bg: #f5f8fa;
$table-bg: #f7fafc;
$input-bg: #f7f9fc;
$sidebar-color: #292751;
$primary-blue: #3b76db;
$primary-yellow: #ffab16;
$primary-orange: #fd7b02;
$primary-red: #d21314;
$primary-green: #19aa7a;
$icon-blue: #43389a;
$primary-border-color: #e4e9f2;
$secondary-border-color:#BBC2D4;
$dashboard-box-shadow: #43389a0f;
$dashboard-title-color: #292751;
$dashboard-icon-blue: #343090;
$dashboard-icon-violet: #5f59f7;
$dashboard-icon-orange: #bf6827;
$dashboard-icon-green: #83a395;
$doughnut-box-shadow: #43389a0f; 
$dashboard-border: #dbd7fa;
$dashboard-border-yellow: #FFE6B8;
$dashboard-total-fee-label-color: #6354c4;
$switch-component-handle-affirmative: #b9e5d7;
$switch-component-handle-negative: #F1B7B8;
$switch-component-info-affirmative: #fafafc;
$tooltip-background-color: #101426;
$tab-background-color: #292751;
$disabled-menu-color: #959595;
$light-bg-white: #FAF6E8;
$light-yellow: #FFAB16;
$border-yellow: #F3A828;
$text-color: #29274E;
$header-title-color: #7886A9;
$select-field-bg-color: #F8F9FA;
$border-color: #E4E9F2;
$blue-base: #4C88FF;
$blue-dark-1: #2461D8;
$grey-dark-4: #3B3C3E;
$menu-title: #999ca5;
$menu-title-selected: #CFD0D2;
$text-grey: #EDEDED;
$text-bg: #090733;
$grey-light-4: #E6E7E7;
$table-header: #f1f6ff;
$card-border: #ECECEC;
$purchase-card-bg:#222B4A;
$grey-base: #75787B;
$light-green-base: #d2eee3;
$light-red-base: #f3d4d3;
$document-card-bg: #EEF4FF;
$grey-light-2: #AEB0B2;
$red-base: #C92224;
$green-base: #19AA7A;
$badge-green-border: #19AA7A1A;
$badge-green-bg: #E8F5F180;
$badge-red-border: #C922241A;
$badge-red-bg: #F8E9EB80;
$badge-yellow-border: #F5B7401A;
$badge-yellow-bg: #FFF6E680;
$badge-orange-text: #C76000;
$badge-orange-border: #FD7B021A;
$badge-orange-bg: #FEF1E480;
$yellow-dark-1: #EF9D00;
$yellow-base: #F5B740;
$overdue-card-bg: #FFF7F7;
$overdue-card-border: #FFC7C7;
$refund-card-bg: #FFF7EA;
$grey-dark-2: #4D4F51;
$red-light-1: #FF9798;
$file-view-bg: #f1f3f4;
$file-view-info: #7987aa;
$display-picture-border: #ffffff80;
$rise-score-slider-red: #DD5E5E;
$rise-score-slider-orange: #FBA44E;
$rise-score-slider-yellow: #BEDD63;
$rise-score-slider-green: #28A160;
$light-yellow-2: #FEEBD9;
$warm-yellow: #F5C750;
$warm-yellow-2: #F19915;
$dark-yellow: #F19915;
$warm-red: #F46242;
$grey-dark-5: #252527;
/* Component colors */

/* Input Field */
$input-border-color: #CFD0D2;
$input-border-color-focus: #1C81CF;
$input-border-box-shadow: 0 0 0 2px
    rgba(
        $color: #006fba,
        $alpha: 0.2,
    );
$input-border-radius: 16px;
$label-color: #7886a9;
$input-border-error-color: #b14048;
$selection-data: #F9F8FF;
$slider-border: #ccc;
/* Default Button */
$button-default-border-color: #ffffff;
$button-default-bg-color: white;
$border-color-light: #DAE6FF;
$button-default-text-color: #292751;
$button-default-border-radius: 12px;
$button-default-letter-spacing: 0.6px;

/* Primary Button */
$button-primary-bg-color: #4537a0;
$button-primary-bg-hover-color: #392e85;
$button-primary-bg-active-color: #5241be;
$button-primary-text-color: white;
$button-primary-border-hover-color: #236ea830;
$button-primary-border-active-color: #43389a80;
$primary-box-shadow: #43389a1a;
$select-box-shadow: #28293d14;

/* Secondary Button */

$button-secondary-bg-color: #e4e9f2;
$button-secondary-bg-hover-color: #e4e9f2;
$button-secondary-bg-active-color: #f1f3f6;
$button-secondary-text-color: #7886a9;
$button-secondary-text-hover-color: #292751;
$button-secondary-text-active-color: #292751;
$button-secondary-border-hover-color: #cfd6e3;
$button-secondary-border-active-color: #d9dee6;

/* Negative Button */

$button-negative-bg-color: #d21314;
$button-negative-bg-hover-color: #b10a0b;
$button-negative-bg-active-color: #db4243;
$button-negative-text-color: #ffffff;
$button-negative-text-hover-color: #ffffff;
$button-negative-text-active-color: #ffffff;
$button-negative-border-hover-color: #ecc1c6;
$button-negative-border-active-color: #ecc1c6;

/* Negative Button */

$button-affirmative-bg-color: #19aa7a;
$button-affirmative-bg-hover-color: #1a805e;
$button-negative-bg-hover-color: #B10A0B;
$button-affirmative-bg-active-color: #47bb94;
$button-affirmative-text-color: #ffffff;
$button-affirmative-text-hover-color: #ffffff;
$button-affirmative-text-active-color: #ffffff;
$button-affirmative-border-hover-color: #c0e4d8;
$button-affirmative-border-active-color: #c0e4d8;

/* Checkbox */
$checkbox-bg-color: #2461D8;
$checkbox-hover-border-color: #2461D8;
$checkbox-text-color: white;
$checkbox-hover-text-color: #2461D8;

/* Radio */
$radio-border-color: rgb(202, 202, 202);
$radio-checked-border-color: #4537a0;
$radio-checked-bg-color: #4537a0;

/* Select */
$select-border-color: #e4e9f2;
$select-hover-border-color: #e4e9f2;
$select-border-radius: 14px;

/* Shadows */
$primary-shadow: 0 8px 22px #0000001a;
$secondary-shadow: 0px 3px 6px #2c28281c;

/* Spacer variables */
$spacer-x: 1rem;
$spacer-y: 1rem;

/* Notification */
$success-notification-color: #19aa7a;

/* Badge */
$badge-completed-color: #e8f5f1;
$badge-inactive-color: #f8e9eb;
$badge-outstanding-color: #fff6e6;
$badge-purchased-color: #eaf0fb;
$badge-partial-return-color: #fef1e4;

/*Date Picker*/
$date-picker-bg-color: #F7F9FC;
$date-picker-hover-border-color: #e4e9f2;
$date-picker-active-border-color: #4537A0;
$date-picker-border-radius: 14px;

/*Statistics colors*/
$stat-green: #4EC7A2;
$stat-orange: #FFB404;
$stat-red: #DB0013;
/*Credit Score*/
$text-badge-color: #C2C6CE;
$badge-background-color: #39405C;

