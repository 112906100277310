@import "src/styles/variables";

.create-refund-form-modal{
    .create-refund-form{
        .checkbox-action-title{
            font: normal normal 500 14px/20px Noto Sans;
            letter-spacing: 0;
            color: $header-title-color;
        }
    }
}