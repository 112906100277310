@import "src/styles/variables";

.customer-list-view {
  .customer-list-view-tabs {
    margin-top: 48px;
  }

  .payment-loss {
    display: flex;
    column-gap: 10.65px;
  }

  .accordion-panel-container {
    margin-bottom: 90px;
    .accordion-panel {
      background-color: white;
    }
  }
}


