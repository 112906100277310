@import "src/styles/variables";

.assetdetails-card {
    border-radius: var(--Border-radius-XL, 20px);
    border: 1px solid $card-border;
    background: var(--White-Base, #FFF);
    margin-top: 32px;
    padding: 24px 0 24px 21px;
    .header{
        color: var(--Black-Base, #000);
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        display: flex;
        align-items: center;
        .label{
            margin: 12px;
        }
    }
    .imei{
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        display: flex;
        .label{
            color: var(--Grey-Base, $grey-base);
            flex: 0 0 20%;
        }
        .value{
            color: var(--Black-Base, #000);
            flex: 0 0 30%;
            display: flex;
            align-items: center;
            .icon{
                margin-left: 8px;
                cursor: pointer;
            }
        }
    }
}