@import "src/styles/variables";

.section-card-component {
    border: 1px solid $card-border;
    border-radius: 20px;
    .section-card-component__header {
        background: url("../../../assets/images/card-bg.svg");
        background-size: cover;
        height: 132px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border: 3px solid #6055bd;

    }
    .section-card-component__content {
    }
}
