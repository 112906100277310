@import "src/styles/variables";

@mixin circle($color) {
  width: 7px;
  height: 7px;
  background-color: $color;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  bottom: 2px;
}

.customer-stat-title {
  margin-top: 30px;
  color: $primary-black;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
}

.customer-stat-container {
  border: 1px solid $card-border;
  border-radius: 20px;
  margin-top: 30px;
  padding: 20px;

  .customer-stat-row {
    margin-top: 15px;
  }

  .stat-number {
    margin-top: 10px;
    font-weight: bold;
  }
  .green-circle {
    @include circle($stat-green);
  }

  .orange-circle {
    @include circle($stat-orange);
  }

  .red-circle {
    @include circle($stat-red);
  }
}