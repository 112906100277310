@import "src/styles/variables";

.maps-autocomplete-component {
    input {
        border-radius: $input-border-radius;
        border: 1.5px solid $input-border-color;
        background: #fff;
        padding: 0 24px;
        height: 50px;
        width: 100%;
        outline: none !important;
        color: $primary-black;
        &::placeholder {
          letter-spacing: 0;
          color: $grey-light-2;
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
        }
        &:focus-visible,
        &:focus {
            border: 1px solid $input-border-color-focus !important;
        }
    }
    &.error {
        input {
            border: 1px solid $input-border-error-color !important;
        }
        .ant-input-affix-wrapper{
            border: 1px solid $input-border-error-color !important;
            input.ant-input{
                border: 1px solid transparent !important;
            }
        }
    }
}
