@import "src/styles/variables";

.checkboxcomponent {
    font-family: "Noto Sans";
    font-style: normal;
    .title{
        color: $primary-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        font-style: normal;
    }
    .label{
        color: var(--Grey-Base, $grey-base);
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 24px;
    }
    .header{
        color: var(--Grey-Base, $grey-base);
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
    }
}