@import "src/styles/variables";

.paymentview {
  .icon-edit{
    font-size: 17px;
    cursor: pointer;
  }
  .payments-details__container {
    margin-right: 20px;
    background: $button-default-border-color;
    box-shadow: 0px 0px 40px $dashboard-box-shadow;
    border-radius: 20px;
    padding: 32px;
    .customer-details-card__container,
    .vendor-details-fee__container {
      > div:not(.customer-name-display-picture) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .image__container {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50px;
        }
      }
      .details__container {
        .header {
          color: $blue-dark-1;
          font-family: Noto Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          margin-bottom: 6px;
          text-transform: uppercase;
        }
        .name {
          color: $basic-black;
          font-family: Noto Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          &.customer{
            cursor: pointer;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        .key-values {
          &:not(:last-child) {
            margin-bottom: 12px;
          }
          i {
            font-size: 18px;
          }
          .key {
            color: $grey-base;
            font-family: Noto Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
          }
          .value {
            color: $basic-black;
            font-family: Noto Sans;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 173.333% */
          }
        }
      }
  }

  .customer-name-display-picture {
    display: flex;
    column-gap: 12px;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    h4 {
      font: normal normal 800 20px/18px Gilroy;
      letter-spacing: 0px;
      color: $primary-black;
      margin-bottom: 4px;
    }
    p {
      font: normal normal 400 14px/16px Gilroy;
      letter-spacing: 0px;
      color: $primary-grey;
      margin-bottom: 0;
    }
  }
  .item,
  .vendor-details-fee__container {
    .purchase-summary-sub-total__container {
      margin-bottom: 10px;
    }
    .label {
      color: $primary-grey;
      font: normal normal 500 14px/20px Gilroy;
      letter-spacing: 0.42px;
      h3,
      h5 {
        letter-spacing: 0px;
        color: $primary-black;
      }
      h3 {
        font: normal normal 500 14px/20px Gilroy;
        span {
          color: $primary-grey;
        }
      }
      h5 {
        font: normal normal 600 15px/21px Gilroy;
      }
    }
    .value {
      font-size: 16px;
      font: normal normal 500 14px/20px Gilroy;
      letter-spacing: 0.42px;
      color: $primary-black;
    }
  }
  .vendor-details-fee__container {
    .label {
      h3 {
        margin: 0;
      }
    }
    .value {
      h3 {
        font: normal normal 600 15px/21px Gilroy;
        letter-spacing: 0px;
      }
    }
    .purchase-summary-total__container {
      .label,
      .value {
        font: normal normal 800 15px/12px Gilroy;
        letter-spacing: 0px;
        color: $primary-black;
      }
    }
  }
  .item {
    margin-top: 20px;
  }
  .settlements-summary-list__container {
    margin-top: 48px;
    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .items-total {
      text-align: right;
      display: inline-block;
    }
    .icon-style{
      width: 18px;
      height: 18px;
    }
    .disabled-pointer {
      cursor: not-allowed !important;
    }
  }
  .settlements-summary-total__container {
    width: 48%;
    margin-right: 0;
    margin-left: auto;
    .details {
      display: flex;
      &:first-child {
        margin-bottom: 20px;
      }
      .label {
        width: 29%;
        font: normal normal 800 15px/22px Gilroy;
        letter-spacing: 0px;
        color: $primary-black;
      }
      .value {
        width: 16%;
        text-align: right;
        font: normal normal 800 15px/22px Gilroy;
        letter-spacing: 0px;
        color: $primary-black;
      }
    }
  }
  .settlements-total__container {
    display: flex;
    width: 48%;

    margin-right: 0;
    margin-left: auto;
    .label {
      width: 29%;
      font-weight: 700;
    }
    .value {
      width: 16%;
      text-align: right;
      font-weight: 700;
    }
  }
}

.payments-details__container-skeleton {
  min-height: 1px;
  height: 248px;
}