@import "src/styles/variables";

.customerreferralrewards {
  .referral_cash,
  .friends_referred,
  .rewards-container {
    width: 280px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid var(--card-border, $card-border);
    background: var(--White-Base, $button-default-border-color);
    margin-bottom: 20px;

    .label {
      color: var(--Grey-Base, $grey-base);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 16px;
      margin-left: 20px;
    }

    .value {
      color: var(--Black-Base, $basic-black);
      font-family: "Noto Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin-top: 4px;
      margin-left: 20px;

      &-2 {
        color: var(--Black-Base, $basic-black);
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        margin-right: 16px;
      }
    }
  }

  .referral_cash {
    height: 92px;
    border: 1px solid var(--Blue-Light-3, $border-color-light);
    background: linear-gradient(276deg, #DDECFF 0.49%, #F9FBFF 97.56%);

    .icon-style-coins {
      margin-top: 17px;
    }
  }

  .friends_referred {
    height: 266px;

    .icon-style-referred {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    .icon-style-referral-left {
      width: 7px;
      height: 7px;
      margin-right: 10px;
    }

    .referral-container {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;

      .referral-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        .referral_label,
        .referral_value {
          color: var(--Grey-Base, $grey-base);
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          line-height: 24px;
        }

        .referral_value {
          font-weight: 600;
          text-align: right;
        }
      }
    }
  }

  .rewards-container {
    width: 518px;
    height: 375px;
    overflow: scroll;

    .rewards-item-container {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      .rewards-item {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        margin-left: 22px;

        .title {
          color: var(--Black-Base, $basic-black);
          /* P3/14 R */
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
        }

        .subtitle {
          color: var(--Grey-Base, $grey-base);
          /* Caption / 12 R */
          font-family: "Noto Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }

        .icon-style-reward-redeemed {
          width: 44px;
          height: 44px;
          flex-shrink: 0;
        }

        .value,
        .value-date {
          color: var(--Grey-Base, $grey-base);
          font-family: "Noto Sans";
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: right;
          margin-right: 22px;
        }

        .value {
          font-weight: 600;

          &.inbound {
            color: var(--Black-Base, $basic-black) !important;
          }

          &.outbound {
            color: var(--Red-Base, $red-base) !important;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .customerreferralrewards {
    .rewards-container {
      width: 100%;
    }
  }
}