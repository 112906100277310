@import "src/styles/variables";

.customerassets-card {
    display: flex;
    flex-direction: column;
    padding: var(--Spacing-2XL, 24px) var(--Spacing-2XL, 24px) 40px;
    gap: 30px;
    border-radius: var(--Border-radius-XL, 20px);
    border: 1px solid #ECECEC;
    background: var(--White-Base, #FFF);
    font-family: "Noto Sans";
    font-style: normal;
    .data{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .label{
            color: var(--Grey-Base, $grey-base);
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }
        .value{
            color: var(--Black-Base, #000);
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
        }
    }
}

.no-data{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans";
    font-style: normal;
    margin-top: 50px;
}