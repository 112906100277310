@import "src/styles/variables";

.approve-customers-controllers {
    position: absolute;
    display: flex;
    right: 0;
    column-gap: 16px;

    &-row{
        width: 100%;
        .right-button{
            min-width: 22px;
        }
        .label{
            color: var(--Grey-Base, $grey-base);
            text-align: right;
            font-family: "Noto Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 18px; /* 150% */
        }
        .value{
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            &.approve {
                color: var(--Green-Base, $success-color);
              }
            &.reject {
                color: var(--Red-Base, $red-base);
            }
        }
    }

    .approve-customer {
        position: absolute;
        box-sizing: border-box;
        left: -140%;
    }
    .ant-dropdown-menu {
        position: absolute;
        right: -19px;
        top: 35px;
    }

    .verify-text {
        display: inline-block;
        padding-right: 5px;
    }
    .ant-dropdown-menu-title-content {
        font-weight: 600;
    }
}
.approve-customers-verify-modal__container {
    .info {
        color: #7987aa;
        text-align: center;
        margin-top: 22px;
    }
    .approve-customers-verify-animation__container {
        margin-top: 32px;
        margin-bottom: 22px;
    }
}
.reject-customer-drawer{
    .ant-drawer-content-wrapper{
        height: 360px !important;
        .ant-drawer-content{
            border-radius: 24px 24px 0 0;
            .ant-drawer-wrapper-body{
                .ant-drawer-header.ant-drawer-header-close-only{
                    display: none;
                }
                .ant-drawer-body{
                    padding: 48px 32px;
                    background: #fff;
                    width: 100%;
                    .ant-btn{
                        width: 100%;
                    }
                    .reject-drawer-header{
                        font-size: 17px;
                        line-height: 24px;
                        font-weight: bold;
                        margin-bottom: 26px;
                    }
                    .reject-drawer-input{
                        margin-bottom: 28px;
                    }
                }
            }
        }
    }
    .reject-drawer-controllers{
        margin-top: 8%;
    }
}
.verify-address-drawer{
    .ant-drawer-content-wrapper{
        height: 640px !important;
        .ant-drawer-content{
            border-radius: 24px 24px 0 0;
            .ant-drawer-wrapper-body{
                .ant-drawer-header.ant-drawer-header-close-only{
                    display: none;
                }
                .ant-drawer-body{
                    padding: 48px 32px;
                    background: #fff;
                    width: 100%;
                    .ant-btn{
                        width: 100%;
                    }
                    .verify-address-drawer-header{
                        font-size: 17px;
                        line-height: 24px;
                        font-weight: bold;
                        margin-bottom: 26px;
                    }
                    .content{
                        margin-bottom: 32px;
                    }
                    .controllers{
                        .action-1{
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }
    .dropdown-field.error input{
        border: none !important;
    }
}
.engine-decision-drawer{
    .ant-drawer-content-wrapper{
        height: 148px !important;
        .ant-drawer-content{
            border-radius: 20px 20px 0 0;
            .ant-drawer-wrapper-body{
                .ant-drawer-header.ant-drawer-header-close-only{
                    display: none;
                }
                .ant-drawer-body{
                    padding: 32px 32px;
                    background: #fff;
                    width: 100%;
                    .ant-btn{
                        width: 100%;
                    }
                    .engine-decision-drawer-header{
                        color: var(--Black-Base, #000);
                        /* H9 / 18 SB */
                        font-family: "Noto Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 28px; /* 155.556% */
                    }
                    .engine-decision-drawer-content{
                        color: var(--Grey-Base, $grey-base);
                        font-family: "Noto Sans";
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px; /* 173.333% */
                        margin-top: 12px;
                    }
                }
            }
        }
    }
}

.verify-neighbourhood-modal{
    .neighbourhood-header{
        color: var(--Black-Base, #000);
        font-family: "Noto Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }
    .neighbourhood-description{
        color: var(--Grey-Base, #75787B);
        font-family: "Noto Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 173.333% */
    }
    .dropdown-field.error input{
        border: none !important;
    }
}

.alert-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img {
      width: 100px;
      margin-bottom: 30px;
    }
    h3 {
      color: #000;
      font-family: Noto Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 160% */
    }
    p {
      color: $grey-base;
      font-family: Noto Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 173.333% */
      text-align: center;
    }
  }

@media (max-width: 480px) {
    .approve-customers-controllers {
        position: fixed;
        border: 1px solid $border-color;
        background-color: $button-default-bg-color;
        bottom: 3rem;
        left: 0;
        z-index: 1;
        text-align: center;
        height: 4rem;
        column-gap: 0;
        .ant-btn.ant-btn-primary,
        button {
            width: 100%;
        }

        .button-component.negative button {
            width: 90% !important;
        }
        .button-component.affirmative button {
            width: 90% !important;
        }
        .ant-dropdown-menu {
            top: -55px;
        }
        &.hide-controller{
            display: none;
        }
        .approve-customers-controllers-row{
            display: flex;
            justify-content: space-evenly;
        }
    }
    .system-decision{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        box-shadow: 0px -10px 40px 0px rgba(56, 89, 154, 0.08);
        padding: 14px 0;
        width: 100%;
        &.approve{
            background: var(--Green-Base, $light-green-base);
        }
        &.reject{
            background-color: $light-red-base;
        }
        .label{
            text-align: center;
            opacity: 1 !important;
            font-family: "Noto Sans";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 173.333% */
            .info-icon{
                margin-right: 10px;
            }
            &.approve{
                color: var(--Green-Base, $primary-green);
            }
            &.reject{
                color: var(--Red-Base, $red-base)
            }
            .value{
                font-weight: 700;
                &.approve{
                    color: var(--Green-Base, $primary-green);
                }
                &.reject{
                    color: var(--Red-Base, $red-base)
                }
            }
        }
    }
    .verification-label{
        position: fixed;
        top: 94%;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 6vh;
        background: var(--Grey-Light-3, $menu-title-selected);
        box-shadow: 0px -10px 40px 0px rgba(56, 89, 154, 0.08);
        color: var(--Grey-Base, $grey-base);
        display: flex;
        justify-content: center;
        align-items: center;
        .label{
            opacity: 1 !important;
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 173.333% */
        }
    }
  }
