@import "src/styles/variables";

.documents-card {
    height: 285px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    p.title{
        display: none;
    }
}
.document-status-icon{
    width: 16px;
    height: 16px;
}
.document-item-card {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    column-gap: 16px;
    cursor: pointer;
    .document-card-icon {
        width: 48px;
        height: 48px;
        background: $document-card-bg;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: 24px;
        }
    }
    .document-item-title {
        margin: 0;
        white-space: normal;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px; 
        font-family: Noto Sans;
    }
    .document-reupload {
        margin-left: auto;
        margin-right: 15px;
    }
    &--label{
        color: $grey-base;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        font-family: Noto Sans;
        margin-bottom: 8px;
    }
}
