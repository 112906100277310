@import "src/styles/variables";

.purchases {
    .vendor__name {
        img {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            border: 0.5px solid $primary-border-color;
            margin-right: 8px;
        }
    }
    .ant-table-content{
        overflow-y: hidden;
        overflow-x: scroll;
    }
}
.picker-field.ant-picker{
    padding: 0 16px;
    &.error {
            border: 1px solid $input-border-error-color !important;
    }
}
