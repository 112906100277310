@import "src/styles/variables";

.customer-details-form {
    .customer-form-modal {
        .modal-component-controllers {
            padding-right: 40px;
        }
        .ant-modal-content{
            .ant-modal-body{
                padding: 8px;
            }
        }
    }
    .customer-form-details__container{
        .section-header{
            color: var(--Black-Base, #000);
            font-family: "Noto Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */
        }
        .ant-row{
            .ant-col{
                max-width: 100%;
            }
            .customer-documents{
                border-radius: 24px 0px 0px 24px;
                border: 4px solid var(--White-Base, #FFF);
                background: var(--Grey-Light-5, #F5F6F6);
                overflow-y: hidden;
                padding: 40px;
            }
            .customer-details{
                padding: 40px;
            }
            .customer-details-header {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                margin-bottom: 20px;
              }
              
              .modal-component-controllers {
                display: flex;
                gap: 10px;
                margin-top: 0;
              }
              
              .label {
                color: var(--Black-Base, #000);
                font-family: "Noto Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px; /* 155.556% */
              }
            .scrollable-section {
                max-height: 800px;
                overflow-y: auto;
                padding-right: 16px;
              }
        }
    }
    .upload-component__wrapper {
        label{
            margin-top: 0;
        }
        .logo-uploaded-component {
            border: 0.2px solid #e4e9f25d;
            border-radius: 100%;
            .logo-reupload {
                right: 4%;
            }
            .logo-image{
                width: 135px;
                height: 135px;
                border-radius: 135px;
                margin: 5px;
                object-fit: contain;
            }   
        }
    }
    .customer-documents-header{
        font: normal normal 600 16px/23px Gilroy;
        letter-spacing: 0;
        color: $primary-black;
    }
    .profile-pic-holder{
        width: max-content;
    }
    .icon-upload:before{
        color: #fff;
    }
    
}
.customer-details-confirmation-modal{
    .modal-component-title{
        font: normal normal 700 18px/30px Gilroy;
        letter-spacing: 0;
        color: $primary-black;
        text-align: center;
    }
}

.add-neighbourhood-menu {
    margin-top: 68px;
}

.ant-dropdown-menu-title-content{
    color: var(--Blue-Dark-1, $blue-dark-1);
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

@media (max-width: 420px) {
    .modal-component-controllers {
        .button-component.fixed button{
            width: 105px;
            padding: 13px 0;
        }
    }

}

.checkboxcomponent{
    &.iscore-checkbox{
        .ant-checkbox-wrapper {
            font-weight: 400 !important;
        }
    }
}