@import "src/styles/variables";

.customer-questionnaire {
  .table-actions {
    display: none;
  }

  .question-text {
    td:first-child {
      vertical-align: top;
    }
  }

  .address {
    margin-bottom: 8px;
  }
}