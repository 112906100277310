@import "src/styles/variables";

.map-component {
    margin-top: 30px;
}
.map-loading {
    height: 100%;
}
.map-container {
    margin-top: 16px;
    height: 243px;
    .map {
        height: 100%;
        border-radius: 20px;
    }
}
