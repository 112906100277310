@import "src/styles/variables";
.modal-component.no-controllers.file-view-modal{
  width: 40% !important; 
  .crop-button{
    margin-left: 10px;
    margin-top: 10px;
  }
}
.image-icon-container{
  text-align: center;
  .rotate-icon {
    max-width: 100%;
    max-height: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
}
.upload-component__wrapper {
  position: relative;
  .header-wrapper{
    &.ar{
      direction: rtl;
      text-align: right;
      margin-right: 1.375rem;
      margin-top: -2.5rem;
    }
    &.en{
      margin-top: -2.5rem;
    }
  }
  label {
    color: $label-color;
    display: inline-block;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 13px;
    margin-top: 32px;
    &.ar{
      color: $blue-dark-1;
      margin-left: 4px;
    }
    &.en{
      color: $blue-dark-1;
    }
    .sub-label {
      font-weight: 400;
    }
  }
  .upload-button-container{
    position: relative;
    text-align: center;
    .rotate-icon {
      margin-left: 3px;
      max-width: 100%;
      max-height: 20px;
    }
    .ant-upload {
      position: absolute;
      right: 0;
      top: -5px;
    }
    .upload-icon {
      margin-right: 16px;
      width: 14px;
    }
    .uploaded-image__action {
      cursor: pointer;
      position: absolute;
      margin-left: 3px;
      margin-top: 9px;
    }
  }
  .image-actions-container {

  }
  .document-preview-card {
    position: relative;

    .card-component-content {
      text-align: center;
      padding: 10px 10px 5px 10px;
    }
    .uploaded-image {
      max-width: 100%;
      max-height: 310px;
      border-radius: 16px;
    }
  }
  .banner-preview-card {
    position: relative;

    .card-component-content {
      text-align: center;
      padding: 10px 10px 5px 10px;
    }
    .uploaded-image {
      border-radius: 16px;
      &.landing{
        width: 192px;
        height: 260.16px;
      }
      &.explainer{
        width: 192px;
        height: 177.6px;
      }
      &.small{
        width: 192px;
        height: 54.154px;
      }
    }
  }
  .cover-picture-uploaded-component {
    position: relative;
    .uploaded-image {
      cursor: pointer;
      width: 100%;
      height: 283px;
      border-radius: 20px 20px 0 0;
      object-fit: contain;
    }
    .uploaded-image__action {
      position: absolute;
      bottom: 24px;
      right: 24px;
      button {
        span {
          vertical-align: bottom;
        }
      }

      .icon-camera {
        font-size: 16px;
        .path1::before {
          color: #fff;
        }
        .path2::before,
        .path3::before {
          color: #fff;
          margin-left: -1.03em;
        }
      }
    }
  }

  .upload-component {
    span.ant-upload {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    div.ant-upload {
      background: #f0f6ff;
      border: 0.5px dashed #3b76db;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .upload-component-button {
      color: $primary-blue;
      text-align: center;
      .icon-upload {
        font-size: 24px;
        &::before {
          color: $primary-blue !important;
        }
      }
    }
    .upload-component-spin {
      padding: 0;
      .ant-spin-text {
        color: #3b76db;
      }
      .ant-spin-dot-item {
        background-color: #3b76db;
      }
    }
    &.document,
    &.document-preview {
      div.ant-upload {
        width: 100%;
        height: 106px;
        border-radius: 16px;
      }
      .upload-component-button {
        .upload-component-button-title {
          margin-top: 5px;
          font-size: 15px;
        }
        .icon-upload {
          font-size: 24px;
        }
      }
    }
    &.document-preview {
      div.ant-upload {
        width: 100%;
        height: 180px;
        border-radius: 16px;
      }
    }
    &.banner-preview {
      div.ant-upload {
        width: 192px;
        height: 154px;
        border-radius: 16px;
      }
      .upload-component-button {
        .upload-component-button-title {
          margin-top: 5px;
        }
        .icon-upload {
          font-size: 24px;
        }
        .banner-upload{
          width: 40px;
          height: 40px;
        }
      }
    }
    &.cover-picture {
      div.ant-upload {
        width: 100%;
        height: 147px;
        border-radius: 16px 16px 0 0;
      }
      .upload-component-button {
        .upload-component-button-title {
          margin-top: 5px;
        }
        .icon-upload {
          font-size: 24px;
        }
        .banner-upload{
          width: 40px;
          height: 40px;
        }
      }
    }
    &.poster {
      div.ant-upload {
        width: 335px;
        height: 200px;
        border-radius: 16px;
      }
    }
    &.logo,
    &.logo-preview {
      div.ant-upload {
        width: 80px;
        height: 80px;
        border-radius: 40px;
      }
      .upload-component-button {
        .upload-component-button-title {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
    &.inline-card {
      .ant-upload {
        background: transparent;
        border: none;
        color: $blue-dark-1;
      }
    }
    &.logo-preview {
      div.ant-upload {
        width: 135px;
        height: 135px;
        border-radius: 135px;
      }
    }
  }
  .logo-uploaded-component {
    position: relative;
    .logo-image {
      width: 80px;
      height: 80px;
      opacity: 1;
      border-radius: 40px;
    }
  }
  .logo-reupload {
    position: absolute;
    bottom: 2%;
    right: -8%;
    .logo-upload-camera-icon {
      width: 32px;
      height: 32px;
      background-color: $primary-color;
      box-shadow: 0 10px 40px $primary-box-shadow;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 16px;
        [class^="path"] {
          &::before {
            color: #fff;
          }
        }
      }
    }
    .upload-icon {
      width: 10px;
    }
  }
}

@media (max-width: 500px) {
  .upload-component__wrapper {
    .upload-button-container {
      .ant-upload {
        position: relative;
        top: 0;
      }
      .uploaded-image__action {
        cursor: pointer;
        position: absolute;
        bottom: -7px;
      }
    }
  }
}