@import "src/styles/variables";

.page-view {
    padding-top: 33px;

    .badge-component{
        display: inline;
    }
    
    .page-view-header-title__container {
        padding-left: 24px;
        padding-right: 28px;
        .back-icon {
            cursor: pointer;
        }
        i {
            font-size: 24px;
            &.icon-more{
                font-size: 18px;
            }
        }
        .vendor-logo{
            width: 50px;
            height: 50px;
            border-radius: 50px;
            margin-right: 12px;
        }
        .title-content{
            display: flex;
            align-items: flex-start;
        }
        .title {
            p{
                margin: 0;
            }
            .main{
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px; /* 133.333% */
                font-family: Noto Sans;
                margin-bottom: 12px;
            }
            .sub{
                color: #000;
                font-size: 14px;
                font-style: normal;
                line-height: 24px; /* 171.429% */
                font-weight: 400;
                font-family: Noto Sans;
                display: flex;
                gap: 20px;
                i{
                    font-size: 18px;
                }
            }
            i {
                vertical-align: -13%;
                margin-right: 8px;
            }
            .title-span {
                color: $primary-grey;
                font-weight: 500;
                font-size: 16px;
                margin-left: 24px
            }
            .promo{
              color: $grey-base;
              font-family: "Noto Sans";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              .promo-id{
                color: var(--Black-Base, #000);
                font-family: "Noto Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
              }
            }
        }
        .page-view-header-title__right {
            width: fit-content;
            margin-right: 0;
            margin-left: auto;
            i {
                vertical-align: -13%;
            }
            .notification-badge {
                margin-right: 24px;
                line-height: 3;
            }
            .ant-badge-count{
                margin-top: 9px;
                margin-right: 4px;
            }
        }
        .vendor-form-edit{
            margin-right: 28px;
            display: flex;
            justify-content: end;
            .ant-btn.ant-btn-primary{
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 12px;
              padding: 7px 20px;
              i{
                font-size: 20px;
              }
              .text{
                color: #FFF;
                font-family: Noto Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 173.333% */
                text-align: center;
              }
            }
        }
        .refund-button{
            font: normal normal 600 16px/23px Gilroy;
            letter-spacing: 0.8px;
            color: $blue-dark-1;
            margin-bottom: 0;
            cursor: pointer;
            text-align: right;
        }
        .customer-purchase-menu{
          text-align: right;
        }
        .ant-dropdown-menu {
          position: absolute;
          right: -19px;
          top: 35px;
        }
        .ant-dropdown-menu-title-content{
          color: var(--Blue-Dark-1, #2461D8);
          font-family: "Noto Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 162.5% */
          .delete-imei{
            color: var(--Red-Base, #C92224);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 162.5% */
            &.inactive{
              color: #000;
            }
          }
        }
        .refund-icon{
            width: 16px;
            margin-right: 4px;
            margin-bottom: 2px;
        }
    }
    .page-view-sub-header{
      padding-left: 4rem;
      .subtitle{
        color: var(--Black-Base, #000);
        font-family: "Noto Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
      }
      .refresh-icon{
        margin-left: 0.75rem;
        cursor: pointer;
      }
    }
    .page-view-content {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 24px;
    }
}

.back-icon {
    cursor: pointer;
    img{
      width: 32px;
      height: 32px;
      margin-right: 24px;
    }
}

@media (max-width: 420px) {
    .page-view{
        margin-top: 50px;
        .page-view-header-title__right{
            margin-right: auto;
        }
        .page-view-content {
        background-color: $button-default-bg-color;
        width: 100vw;
        }
    }
}

@media (max-width: 480px) {
  .page-view.pending-approval-view{
    margin-top: 50px;
    .page-view-header-title__container {
      padding-left: 16px;
      padding-right: 0;
      .vendor-logo {
        width: 32px;
        height: 32px;
        border-radius: 32px;
      }
      .title {
        .sub {
          flex-direction: column;
          gap: 10px;
        }
      }
    }
    .page-view-header-title__right {
      margin-right: auto;
    }
    .page-view-content {
      background-color: $button-default-bg-color;
      width: 100vw;
      padding-top: 0;
    }
  }
  .back-icon {
    img {
      margin-right: 16px;
    }
  }
}
