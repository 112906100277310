@import "src/styles/variables";
.third-party-card {
  &:hover {
    .expand-details-icon {
      display: block;
    }
    .max-height{
      height: auto !important;
    }
  }
  .ant-divider-horizontal{
    margin: 1rem 0 0.5rem 0;
  }
  .reapplied {
    border-radius: 24px 24px 0 0;
    border-top: 1px solid $border-yellow;
    border-right: 1px solid $border-yellow;
    border-left: 1px solid $border-yellow;
    background: linear-gradient(180deg, #FFF5E6 0%, #FFF 100%);
    border-bottom: none;
    .card-component-content{
      padding-bottom: 0;
    }
    .reapplication-container{
      display: flex;
      flex-direction: column;
      .reappy-header{
        display: flex;
        align-items: center;
        margin-bottom: 0.25rem;
        .title{
          background: linear-gradient(110deg, $warm-yellow 8.37%, $warm-yellow-2 30.15%, $warm-red 49.77%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 18px;
          margin-left: 0.5rem;
        }
      }
      .reapply-content{
        color: $grey-dark-5;
        font-family: "Noto Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-top: 0.25rem;
        padding-bottom: 1rem;
      }
      .reapply-divider{
        margin-bottom: 0.5rem !important;
      }
    }
  
  }

  .approve-customer-third-party-details-card {
    height: 282px;
    overflow: scroll;
    padding-top: 0;
    &.no-border{
      border-radius: 0 0 24px 24px;
      border-top: none;
      height: 196px;
      .card-component-content{
        padding-top: 0;
      }
    }

    .approve-customer-third-party-details-card__content {
      padding: 8px 32px 2px 8px;
      .subheader{
        color: var(--Black-Base, #000);
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 162.5% */
        padding-bottom: 1rem;
      }

      .approve-customer-third-party-details-card-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          margin-bottom: 0;
        }
        .approve-customer-third-party-details-card-item-title {
          color: $primary-grey;
          min-width: 25%;
          font-size: 1rem;
          font-weight: 600;
          line-height: 26px;
        }
        .kyc-status{
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .third-party-card {
    &.card-component {
      box-shadow: none !important;
      margin-top: 10px;
      padding: 0;
    }
    .approve-customer-third-party-details-card {
        height: 100%;
        border: none;
        border-radius: 0;
        ::-webkit-scrollbar {
          display: none;
        }
        .card-component-content{
          padding: 0;
        }
        .approve-customer-third-party-details-card__content {
            padding: 0 ;
        }
    }
    .documents-card {
      box-shadow: none !important;
      border-radius: 0 !important;
      height: max-content;
      margin-top: -23px;
      padding-bottom: 45px;
    }
  }
}
