@import "src/styles/variables";

.card-skeleton-component.dashboard__container-skeleton{
  &.metrics{
    min-height: 123px;
  }
  &.graph{
    min-height: 379px;
  }
}
.dashboard-view{
  .ant-divider.ant-divider-horizontal{
    margin: 0;
  }
  .card-skeleton-component{
    box-shadow: none;
  }
  .header {
    .date-range-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .date {
        display: flex;
        align-items: center;
        h3 {
          margin: 0;
        }
      }

      .report{
        cursor: pointer;
      }
  
      .label {
        color: var(--Blue-Dark-1, $checkbox-bg-color);
        text-align: right;
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
      }
      .icon-folder{
        width: 20px;
        height: 20px;
        margin-top: -7px;
      }
    }
  }
  
}

.dashboard-header {
  align-items: baseline;
  display: flex;
  &.space-between{
    justify-content: space-between;
  }
  .title{
    font: normal normal 600 16px/23px Gilroy;
    letter-spacing: 0px;
    color: $primary-black;
  }
  .dashboard-title {
    font: normal normal 600 18px/26px Gilroy;
    letter-spacing: 0px;
    color: $primary-black;
    margin-right: 24px;
  }
  &.purchases{
    height: 1px;
  }
}

.dashboard__container {
  background: $button-default-border-color;
  box-shadow: 0 0 40px $dashboard-box-shadow;
  padding: 28px 24px;
  border-radius: 26px;
  .graph{
    margin-top: 20px;
  }
  &.header{
    padding: 34px 40px;
  }
  .title {
    font: normal normal 600 16px/23px Gilroy;
    letter-spacing: 0;
    color: $primary-black;
  }
  .header-card {
    &.blue {
      .icon__container {
        background-color: $dashboard-icon-blue;
      }
      .item {
        .label {
          color: $dashboard-icon-blue;
        }
      }
    }
    &.violet {
      .icon__container {
        background-color: $dashboard-icon-violet;
      }
      .item {
        .label {
          color: $dashboard-icon-violet;
        }
      }
    }
    &.brown {
      .icon__container {
        background-color: $dashboard-icon-orange;
      }
      .item {
        .label {
          color: $dashboard-icon-orange;
        }
      }
    }
    &.green {
      .icon__container {
        background-color: $dashboard-icon-green;
      }
      .item {
        .label {
          color: $dashboard-icon-green;
        }
      }
    }
    .image {
      .icon__container {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        .icon-component {
          font-size: 24px;
          ::before {
            color: $button-default-border-color;
          }
        }
      }
    }
    .item {
      margin-left: 16px;
      .value {
        font: normal normal bold 28px/41px Gilroy;
        letter-spacing: 0.84px;
        color: $primary-black;
      }
    }
  }
}
.create-report-btn {
  margin-bottom: 20px;
}