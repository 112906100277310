@import "src/styles/variables";

.purchase-overdue-payments-card {
  margin-top: 48px;
  &.purchase-card-table {
    padding: 24px;
    background: $overdue-card-bg;
    border: 1px solid $overdue-card-border;
    .ant-table {
      background: inherit;
      .ant-table-thead > tr > th {
        background: inherit;
      }
      .ant-table-tbody {
        margin-top: 0;
        &::before {
          display: none;
        }
        tr td {
          color: #000;
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          line-height: 26px; /* 162.5% */
        }
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
        background: unset;
      }
    }
  }
  .table-component {
    padding: 0;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .payment__container {
    background: #ffffff;
    box-shadow: 0 0 40px $dashboard-box-shadow;
    border-radius: 20px;
    margin-top: 16px;
    padding: 24px 32px;
    .item {
      .label {
        color: $primary-grey;
        font-size: 13px;
      }
      .value {
        font-size: 16px;
        font-weight: 700;
        margin-top: 12px;
      }
    }
  }
  .value{
    font-weight: 700;
  }
  .due-on__container {
    display: flex;
    align-items: center;
    column-gap: 10.65px;
    font-weight: 700;
    .late-date{
      flex: 0 0 40%;
    }
    .badge{
      flex: 0 0 30%;
      .badge-component {
        margin-top: -3px;
      }
    }
  }
  .container{
    cursor: pointer;
    .remove-late-fee{
      color: var(--Red-Base, $red-base);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.remove-late-fee-confirmation-modal{
  .alert-info{
    .description{
      color: var(--Black-Base, #000);
      text-align: center;
      font-family: "Noto Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 160% */
    }
    .subtitle{
      color: var(--Grey-Base, $grey-base);
      text-align: center;
      font-family: "Noto Sans";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 173.333% */
    }
  }
}
