@import "variables";
@import "helpers";
@import "@antOverrides";
@import "icons";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 300;
  src: local("Gilroy"), local("Gilroy"), url("../assets/fonts/Gilroy-Light.woff2") format("woff2");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  src: local("Gilroy"), local("Gilroy"), url("../assets/fonts/Gilroy-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy"), local("Gilroy"), url("../assets/fonts/Gilroy-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  src: local("Gilroy"), local("Gilroy"), url("../assets/fonts/Gilroy-Semibold.woff2") format("woff2");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  src: local("Gilroy"), local("Gilroy"), url("../assets/fonts/Gilroy-Bold.woff2") format("woff2");
}

/* General component styles */
body {
  background-color: #fbfbfc;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*{
  -ms-overflow-style: none;  
  scrollbar-width: none;  
  &::-webkit-scrollbar {
    display: none;
  }
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input {
  font-family: Noto Sans !important;
  color: $primary-black;
  line-height: 1;
}

.app-router {
  min-height: 100vh;
}

.name-display-picture {
  img {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    border: 0.5px solid $primary-border-color;
    margin-right: 8px;
  }
}
.payment-profit {
  color: $primary-green;
}
.payment-loss {
  color: $primary-red !important;
}

.success {
  color: $primary-green;
}

.failure {
  color: $primary-red;
}

.pending {
  color: $primary-orange;
}

.empty-view{
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 9%;
  .empty-img{
    width: 95px;
    height: 70px;
  }  
  .empty-text{
    text-align: center;
    margin-top: 24px;
    color:#7886A9;
    font: normal normal 500 14px/24px Noto Sans;
  }
}

.ant-picker.ant-picker-range.dashboard-filter{
  background: none;
  overflow: hidden;
  border: none;
  height: 36px;
  .ant-picker-active-bar{
    background: $primary-color;
  } 
}
.ant-dropdown-menu.ant-dropdown-menu-sub.ant-dropdown-menu-vertical{
  border-radius: 16px;
}

.table-component-divider{
  margin: 14px 0;
}

input {
  &::placeholder {
    letter-spacing: 0;
    color: $grey-light-2;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
}

.max-height{
  -moz-transition: height 0.1s; 
  -webkit-transition: height 0.1s; 
  -ms-transition: height 0.1s; 
  -o-transition: height 0.1s; 
  transition: height 0.1s;  
  height: 100% !important;
}

.expand-details-icon{
  font-size: 18px;
  position: absolute;
  bottom: -9px;
  background-color: #eee;
  border-radius: 20px;
  font-weight: 100;
  color: $primary-grey;
  padding: 3px;
  left: calc(50% - 9px);
  display: none;
}

.kyc-value{
  font-weight: 600;
    .status-icon{
      width: 16px;
      height: 16px;
    }
    &.negative{
      color: $red-base;
    }
    &.positive{
      color: $primary-green;
    }
    &.pending{
      color: $yellow-dark-1;
    }
    &.button, .ant-btn.ant-btn-primary{
      width: 100%;
    }
}