@import "src/styles/variables";

.slidercomponent {
  pointer-events: none;
  .ant-slider-rail {
    background: linear-gradient(
      to left,
      $rise-score-slider-green,
      $rise-score-slider-yellow,
      $rise-score-slider-orange,
      $rise-score-slider-red
    );
  }

  .ant-slider-track {
    display: none;
  }

  .ant-slider-handle {
    border-radius: 5px;
    background-color: $button-default-border-color;
    border: 1px solid $slider-border;
    margin-top: -5px !important;
    width: 16px;
    height: 14px;
    flex-shrink: 0;
  }

  .ant-slider-mark-text,
  .ant-slider-mark-text-active {
    white-space: nowrap;
    color: $button-default-border-color;
    font-family: "Noto Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    margin-top: 6px;
  }
}
