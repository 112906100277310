@import "../../../../styles/variables";

.report-form{
    .ant-picker{
        width: 100% !important;
    }
    .ant-col-22 {
        min-width: 100%;
    }
    .send-self{
        color: $checkbox-bg-color;
        cursor: pointer;
        margin-top: 2px;
        margin-left: 7px;
    }
    .description{
        margin-top: 20px;
    }
}