@import "src/styles/variables";

.approve-documents-controllers {
    display: flex;
    column-gap: 16px;
    align-items: flex-end;
    &-row{
        width: 100%;
        .right-button{
            min-width: 22px;
        }
    }
    .button-component{
        margin-top: 0;
    }
}
.approve-documents-verify-modal__container {
    .info {
        color: $file-view-info;
        text-align: center;
        margin-top: 22px;
    }
    .approve-documents-verify-animation__container {
        margin-top: 32px;
        margin-bottom: 22px;
    }
}

@media (max-width: 480px) {
    .approve-documents-controllers {
        position: fixed;
        width: 100%;
        border: 1px solid $border-color;
        background-color: $button-default-bg-color;
        padding: 14px 0;
        margin-top: 5px;
        top: auto;
        bottom: 0;
        left: 0;
        z-index: 1;
        text-align: center;
        justify-content: center;
        .ant-btn.ant-btn-primary,
        .button-component.negative button {
            width: 120px !important;
        }

        .ant-dropdown-menu {
            top: -55px;
        }
    }
  }
