@import "src/styles/variables";

.payment-metrics-component{
    .card-skeleton-component{
        min-height: 233px;
    }

    .dashboard-header{
        padding: 20px;
    }

    .content {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 40px #43389a0f;
        border-radius: 26px;
        padding: 36px 40px;
        .title{
          font: normal normal 600 18px/26px Gilroy;
          letter-spacing: 0px;
          color: $primary-black;
          margin-right: 24px;
          padding-bottom: 16px;
        }
        .bottom-content{
          display: flex;
          margin: auto;
          justify-content: space-between;
        }
        .independent-kpi {
          .label {
            font: normal normal normal 13px/20px Gilroy;
            letter-spacing: 0px;
            color: $primary-grey;
            margin-bottom: 12px;
          }
          .value {
            font: normal normal 600 20px/29px Gilroy;
            letter-spacing: 0.6px;
            color: $primary-black;
          }
          .first{
            margin-bottom: 38px;
          }
          .second{
            h3{
                margin-bottom: 0;
            }
          }
          .third{
            margin-bottom: 29px;
            margin-top: 25px;
          }
        }
      }

}