@import "src/styles/variables";

.purchase-status-card {
  border-radius: 20px;
  padding: 25px 32px;
  &.active{
    background: $success-color 0% 0% no-repeat padding-box;
    .content {
      .purchase-status__header {
        .switch-component__wrapper {
          .switch-component {
            button {
              background: $button-affirmative-bg-hover-color;
            }
            .ant-switch-handle::before {
              background: $switch-component-handle-affirmative;
            }
          }
        }
      }
    }
  }
  &.inactive{
    background: $button-negative-bg-active-color 0% 0% no-repeat padding-box;
    .content {
      .purchase-status__header {
        .switch-component__wrapper {
          .switch-component {
            button {
              background: $button-negative-bg-hover-color;
            }
            .ant-switch-handle::before {
              background: $switch-component-handle-negative;
            }
          }
        }
      }
    }
  }
  .content {
    .purchase-status__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font: normal normal 600 24px/48px Gilroy;
        color: $button-primary-text-color;
        margin: 0;
      }
    }
    .text {
      p {
        font: normal normal 400 12px/20px Gilroy;
        color: $switch-component-info-affirmative;
      }
    }
  }
}
