@import "src/styles/variables";

.date-picker-field {
    margin-top: 22px;

    .ant-picker {
        display: block;
    }

    &.error {
        .ant-picker {
            border: 1px solid $input-border-error-color !important;
        }
    }
}
