@import "src/styles/variables";

.approve-customers-view {
    .customer-list-view-tabs {
        margin-top: 48px;
    }
}

.approve-customers-view {
    position: relative;
    .customer-divider{
        display: none;
        visibility: hidden;
    }
    .customer-questionnaire{
        padding-top: 24px;
        .table-component{
            padding: 6px 8px;
            border: 1px solid $card-border;
            border-radius: 20px;
            overflow: hidden;
        }
        .table-actions{
            display: none !important;
        }
    }
}

@media (max-width: 480px) {
    .customer-divider{
        display:block !important;
        visibility: visible !important;
    }
    .documents-card{
        border: none;
        border-radius: 0;
        height: 100%;
        .card-component-content{
            padding: 0;
        }
    }
    .approve-customers-view {
        .customer-questionnaire{
            padding-top: 10px;
        }
    }
    .page-view-content .approve-customers-view{
        .ant-collapse{
            background-color: #FFF;
            border: none;
            border-radius: 0;
        }
        .ant-collapse > .ant-collapse-item {
            padding: 1rem 0;
        }
    }
}

