@import "src/styles/variables";

.purchase-return-summary-card {
    background: #ffffff;
    box-shadow: 0px 0px 40px #43389a1a;
    border-radius: 20px;
    padding: 32px 40px;
    .label {
        letter-spacing: 1.2px;
        color: $secondary-grey;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
    }
    .header {
        display: flex;
        justify-content: space-between;
    }
    .return-list__container {
        margin-top: 27px;
        .return-item {
            display: flex;
            margin-bottom: 32px;
            font-weight: 500;
            .product {
                width: 70%;
            }
            .quantity {
                width: 10%;
                text-align: end;
                text-decoration: underline;
                font-style: italic;
            }
            .price {
                width: 20%;
                text-align: end;
                font-weight: 600;
            }
        }
    }
    .refund-total__container {
        display: flex;
        justify-content: space-between;

        .total__label {
            font-weight: 600;
        }
        .value {
            font-weight: 700;
        }
    }
    .return-receipt__container {
        .label {
            margin-bottom: 26px;
        }
    }
}
