@import "src/styles/variables";

.card-component {
  border-radius: 20px;
  padding-bottom: 5px;
  border: 1px solid $card-border;
  .card-component-content {
    overflow-y: auto;
    padding: 24px 28px;
  }
  &.banner-preview{
    border: none;
  }
}
