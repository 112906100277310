@import "src/styles/variables";

.payment-adjustment-content {
    .table-component{
        background: transparent;
        box-shadow: none;
        padding: 0;
    }
  h3 {
    margin-bottom: 12px;
    color: #000;
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
  }
  p {
    margin-bottom: 22px;
    color: $grey-dark-2;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
}
