@import "src/styles/variables";

.pending-settlements {
    .vendor__name {
        img {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            border: 0.5px solid $primary-border-color;
            margin-right: 8px;
        }
    }
    .ant-picker{
        padding: 0 20px 0 18px;
    }
    .table-component{
        border-radius: 20px 20px 0 0 !important;
        &.filter {
            .table-actions {
                display: flex;
                column-gap: 16px;
            }
        }
        .selection-data{
            margin: 24px 0;
            background-color: $document-card-bg;
            border-radius: 16px;
            padding: 16px;
            display: flex;
            justify-content: space-between;
            .label{
                color: $grey-base;
                text-transform: uppercase;
                opacity: 1;
                font-family: Noto Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 150% */
                .value{
                    font-family: Noto Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    margin-left: 40px;
                    color: $primary-black;
                }
            }
            .label-select{
                text-decoration: underline;
                font: normal normal 600 15px/21px Gilroy;
                letter-spacing: 0.6px;
                color: $checkbox-bg-color;
                cursor: pointer;
            }
            .label-deselect{
                text-decoration: underline;
                font: normal normal 600 15px/21px Gilroy;
                letter-spacing: 0.6px;
                color: $header-title-color;
                opacity: 1;
            }
        }
    }
    .pagination-component{
        padding: 0 !important;
        border-radius: 0 0 20px 20px !important;
        margin-bottom: 32px !important;
        .ant-pagination,
        .mini {
          border-radius: 0 0 20px 20px !important;
        }
    }
}