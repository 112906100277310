@import "src/styles/variables";

.pagination-component {
  padding-bottom: 20px;
  position: relative;

  .ant-pagination,
  .mini {
    padding: 15px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .ant-pagination-total-text{
    letter-spacing: 0;
    position: absolute;
    left: 10px;
    color: $grey-base;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  ul li {
    font-size: 12px;
    padding: 0 10px !important;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    margin: 0 11px !important;
    border: 0.5px solid var(--light-light-0);
    background: #F7F9FC 0% 0% no-repeat padding-box;
    border: 0.5px solid #E4E9F2;
    border-radius: 50% !important;
    .anticon {
      font-size: 10px !important;
      vertical-align: 0rem;
    }
  }
  
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 43px !important;
    height: 36px !important;
    &:hover,
    &:focus {
      color: $primary-border-color !important;
    }
  }

  .ant-pagination-item a {
    padding: 6px 0;
    letter-spacing: 0.7px;
    color: $grey-base;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .ant-pagination-item-active {
    background: $blue-dark-1 0% 0% no-repeat padding-box;
    border: none !important;
    border-radius: 50%;
  }

  .ant-pagination-item-active a {
    color: #fff !important;
  }
}
