@import "src/styles/variables";

.modal-component.large.partner-settlement-form-modal{
    .partner-settlement-form{
        .input-field, .date-picker-field{
            margin: 0;
        }
        .collection-info {
            width: 635px;
            background-color: $light-bg-white;
            padding: 18px;
            border-radius: 10px;
            margin-top: 30px;
            display: flex;
            align-items: center;
            font: normal normal medium 14px/20px Gilroy;
          }
          
          .collection-info .icon-info {
            margin-right: 10px; 
            .path1::before,
            .path2::before,
            .path3::before,
            .path4::before {
                color: $light-yellow !important;
                font-size: 20px;
            }
          }
    }
    .modal-component-controllers{
        margin: 0;
    }
    .settlements-total__container,.settlements-amount__container{
        width: 100%;
        .details {
            display: flex;
            justify-content: space-between;
            color: $primary-black;
            margin-bottom: 20px;
            .label{
                font: normal normal 500 14px/20px Gilroy;
                letter-spacing: 0;
                span{
                    color: $primary-grey;
                }
            }
            .value {
                text-align: right;
                font: normal normal 600 14px/20px Gilroy;
            }
        }
    }
    .partner-name-display-picture {
        display: flex;
        column-gap: 15px;
        align-items: center;
        margin-bottom: 32px;
        img {
            width: 56px;
            height: 56px;
            border-radius: 50%;
        }
        h4 {
            font: normal normal 700 20px/22px Gilroy;
            letter-spacing: -0.4px;
            margin-bottom: 4px;
        }
        p {
            margin-bottom: 0;
            font: normal normal 400 14px/20px Gilroy;
        }
        .icon-customers {
            font-size: 15px;
            border: 1px solid $input-border-color;
            .path1, .path2, .path3{
              &::before{
                color: $secondary-border-color;
            }
            }
            padding: 10px;
            width: 50px;
            height: 50px;
            border-radius: 20px;
          }
    }
    .settlements-amount__container{
        .details{

            &:first-child{
                margin-bottom: 0;
            }
            .label,.value{
                font: normal normal 700 16px/23px Gilroy;
            }
        }
    }
}