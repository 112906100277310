@import "src/styles/variables";
.mobile-navbar {
    background: linear-gradient(298deg, #EEFEF3 34.77%, #E5F9F1 49.16%, #D7F5F6 68.25%, #E8F1F4 84.81%);
    position: absolute;
    display: flex;
    width: 100vw;
    padding-bottom: 15px;
    z-index: 1;

    .navbar-app-logo__img {
      width: 64px;
      height: 30px;
      display: block;
      margin-left: 20px;
      margin-right: auto;
      margin-top: 22.36px;
    }
    .navbar-button{
      padding-right: 14px;
      margin-right: 12px / 100vw;
      margin-top: 22.36px;
    }

    .mobile-navbar-item__title{
      color: #000;
      font-family: Noto Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
    }
  
    .ant-btn{
      color: #000;
      background-color: transparent !important;
      box-shadow: none;
    }
    .ant-btn-icon-only > * {
      font-size: 24px !important;
    }
}

  .navbar-menu-header,
  .ant-drawer-body{
    padding: 0;
    width: 90vw;
    background: linear-gradient(178deg, #EEFEF3 -1.76%, #E5F9F1 27.52%, #D7F5F6 66.33%, #E8F1F4 100%);
  }

  .navbar-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
  
    .navbar-menu-app-logo__img {
      width: 120px;
      margin: 21px 24px 67px 24px;
    }
  
    .navbar-menu_close {
      font-size: 35px;
      margin-top: -45px;
      margin-right: 35px;
    }
  }

.ant-drawer.ant-drawer-left.ant-drawer-open.mobile-drawer{
  .ant-drawer-content-wrapper{
    .ant-drawer-content{
      .ant-drawer-wrapper-body{
        .ant-drawer-body{
          .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light{
            background-color: transparent !important;
            border: none;
            .ant-menu-item.ant-menu-item-only-child.mobile-navbar-item,
            .ant-menu-submenu.ant-menu-submenu-vertical.mobile-navbar-sub-item{
              color: $menu-title;
              font-weight: 500;
              text-align: left;
              height: 50px;
              line-height: 50px;
              margin-top: 0;
              background: none;
              padding: 0 10px;
              padding-left: 10px !important;
              &:hover,
              &.ant-menu-item-selected {
                  .mobile-navbar-item__title {
                    background: linear-gradient(289deg, rgba(117, 120, 123, 0.2) 3.87%, rgba(174, 176, 178, 0.2) 100%) !important;
                      border-radius: 10px;
                  }

                  &::before {
                      display: none;
                  }
                  &::after{
                      display: none;
                  }
              }
              .mobile-navbar-item__title {
                  padding-left: 1em;
                  display: flex;
                  align-items: center;
                  column-gap: 10px;
                  .icon-component {
                      font-size: 20px;
                      [class^="path"] {
                          &::before {
                              color: $menu-title;
                          }
                      }
                  }
              }
              &:not(:last-child) {
                  margin-bottom: 10px;
              }
              &:active {
                  background: none;
              }
              &.ant-menu-item-selected {
                  .mobile-navbar-item__title {
                      color: $grey-dark-4;
                      font-weight: 600;
                      .icon-component {
                          font-size: 20px;
                          [class^="path"] {
                              &::before {
                                  color: $grey-dark-4;
                              }
                          }
                      }
                  }
              }
            }
          }
        }
      }
    }
  }
}