@import "src/styles/variables";

.config-list-view{
    position: relative;
    .data-type{
        border-radius: var(--Border-radius-XL, 20px);
        background: var(--Blue-Light-4, $document-card-bg);
        height: auto;
        padding: 2.25rem 0 2.25rem 2.25rem;
        margin-bottom: 2em;
    }
    .info-text{
        color: $secondary-grey;
        font-weight: 500;
        .icon-image{
            width: 10px;
            height: 10px;
            margin-left: 20px;
        }
    }
    .label {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px;
    }
}
