@import "src/styles/variables";

.switch-component__wrapper {
    label {
        color: $label-color;
        display: inline-block;
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 13px;
        margin-top: 32px;
    }
    .switch-component {
        display: flex;
        align-items: center;
        .ant-switch-checked {
            .ant-switch-handle {
                left: calc(100% - 28px - 2px);
            }
        }
        .ant-switch {
            width: 52px;
            height: 32px;
            background-color: $primary-red;

            .ant-switch-handle {
                width: 28px;
                height: 28px;
                &::before {
                    border-radius: 14px;
                }
            }
        }
        .ant-switch-checked {
            background-color: $primary-green;
        } 
        .switch-title {
            font-weight: 600;
            margin-left: 14px;
            &.checked {
                color: $primary-green;
            }
            &.unchecked {
                color: $primary-red;
            }
        }
    }
}
