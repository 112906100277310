@import "src/styles/variables";

.reviewneighbourhood{
    .neighbourhood-review-action{
        color: var(--Blue-Dark-1, $blue-dark-1);
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
    }
}