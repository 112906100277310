@import "src/styles/variables";

.bar-chart-legend-component {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 40px $doughnut-box-shadow;
  border-radius: 26px;
  padding: 4px;
  height: auto;
  
  .dashboard-header {
    padding: 4px;
  }

  .chart-container {
    display: flex;
    height: 250px;
    max-height: auto;
    overflow-y: auto;
  }

  .chart-legend {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 300px;

    .legend-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .legend-color {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}
