@import "src/styles/variables";

.past-collection-view {
  .icon-edit{
    font-size: 17px;
    cursor: pointer;
  }
  .partner-details__container {
    .partner-details-card__container,
    .partner-details-fee__container {
      max-height: 248px;
      background: #ffffff;
      box-shadow: 0 0 40px #43389a0f;
      border-radius: 20px;
      padding: 32px;
      > div:not(.partner-name-display-picture) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    .collection-info {
      max-width: 573px;
      background-color: #FAF6E8;
      padding: 18px;
      border-radius: 10px;
      margin-top: 30px;
      display: flex;
      align-items: center;
    }
    
    .collection-info .icon-info {
      margin-right: 10px; 
      .path1::before,
      .path2::before,
      .path3::before,
      .path4::before {
          color: #FFAB16 !important;
          font-size: 20px;
      }
    }
    
    .collection-info .text {
      margin: 0;
      line-height: 20px;
      font: normal normal 500 14px/20px Gilroy;
      letter-spacing: 0.42px;
      color: $primary-black;
    }
    }
  }

  .partner-name-display-picture {
    display: flex;
    column-gap: 12px;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    h4 {
      font: normal normal 800 20px/18px Gilroy;
      letter-spacing: 0;
      color: $primary-black;
      margin-bottom: 4px;
    }
    p {
      font: normal normal 400 14px/16px Gilroy;
      letter-spacing: 0;
      color: #7886a9;
      margin-bottom: 0;
    }
    .icon-customers {
      font-size: 15px;
      border: 1px solid $input-border-color;
      .path1, .apth2, .path3{
        &::before{
          color: $secondary-border-color;
      }
      }
      padding: 10px;
      width: 50px;
      height: 50px;
      border-radius: 20px;
    }
  }
  .item,
  .partner-details-fee__container {
    max-height: 248px;
    .purchase-summary-sub-total__container {
      margin-bottom: 22px;
    }
    .label {
      color: $primary-grey;
      font: normal normal 500 14px/20px Gilroy;
      letter-spacing: 0.42px;
      h3,
      h5 {
        letter-spacing: 0;
        color: $primary-black;
      }
      h3 {
        font: normal normal 500 14px/20px Gilroy;
        span {
          color: #7886a9;
        }
      }
      h5 {
        font: normal normal 600 15px/21px Gilroy;
      }
    }
    .value {
      font-size: 16px;
      font: normal normal 500 14px/20px Gilroy;
      letter-spacing: 0.42px;
      color: $primary-black;
    }
  }
  .partner-details-fee__container {
    max-height: 248px;
    .label {
      h3 {
        margin: 0;
      }
    }
    .value {
      h3 {
        font: normal normal 600 15px/21px Gilroy;
        letter-spacing: 0;
      }
    }
    .purchase-summary-total__container {
      .label,
      .value {
        font: normal normal 800 15px/22px Gilroy;
        letter-spacing: 0;
        color: $primary-black;
      }
    }
  }
  .item {
    margin-top: 20px;
  }
  .settlements-summary-list__container {
    margin-top: 48px;
    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .items-total {
      text-align: right;
      display: inline-block;
    }
  }
  .settlements-summary-total__container {
    width: 48%;
    margin-right: 0;
    margin-left: auto;
    .details {
      display: flex;
      &:first-child {
        margin-bottom: 20px;
      }
      .label {
        width: 29%;
        font: normal normal 800 15px/22px Gilroy;
        letter-spacing: 0;
        color: $primary-black;
      }
      .value {
        width: 16%;
        text-align: right;
        font: normal normal 800 15px/22px Gilroy;
        letter-spacing: 0;
        color: $primary-black;
      }
    }
  }
  .settlements-total__container {
    display: flex;
    width: 48%;

    margin-right: 0;
    margin-left: auto;
    .label {
      width: 29%;
      font-weight: 700;
    }
    .value {
      width: 16%;
      text-align: right;
      font-weight: 700;
    }
  }
}

.partner-details__container-skeleton {
  min-height: 1px;
  height: 248px;
}