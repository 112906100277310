@import "src/styles/variables";

.purchase-summary-card {
  height: 390px;
  padding: 40px 32px;
  background: $purchase-card-bg;
  color: #fff;
  .ant-divider.ant-divider-horizontal.ant-divider-dashed{
    border-color: #fff;
    opacity: 0.5;
  }
  p.title {
    padding: 0 0 32px;
  }
  .card-component-content {
    padding: 0;
  }
  .purchase-summary-list__container {
    height: 250px;
    overflow: scroll;
    .purchase-summary-item {
      display: flex;
      margin-bottom: 32px;
      font-weight: 500;
      .product {
        width: 70%;
      }
      .quantity {
        width: 18%;
        text-align: end;
        text-decoration: underline;
        font-style: italic;
      }
      .price {
        width: 12%;
        text-align: end;
        font-weight: 600;
      }
    }
  }
  .ant-divider {
    width: 100%;
  }
  .purchase-summary-items-total__container {
    padding-right: 16px;
    .item {
      display: flex;
      justify-content: space-between;
      .label {
        font-weight: 500;
      }
      .value {
        font-weight: 600;
      }
      &:first-child {
        margin-bottom: 24px;
      }
    }
  }
  .purchase-summary-total__container {
    display: flex;
    justify-content: space-between;
    padding-right: 16px;

    .label,
    .value {
      font: normal normal 800 16px/24px Gilroy;
      letter-spacing: 0;
      color: #FFF;
    }
  }
  .purchase-summary-sub-total__container {
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    .label,
    .value {
      h3 {
        font: normal normal 600 16px/24px Gilroy;
        letter-spacing: 0;
        color: #fff;
        &.negative{
          color: $primary-red;
        }
        &.discount{
          color: $red-light-1;
        }
      }
      h5 {
        font: normal normal 500 14px/24px Gilroy;
        letter-spacing: x;
        color: #fff;
        opacity: 0.8;
      }
      h5,
      h3 {
        margin-bottom: 5px;
      }
    }
    .label {
      h5 {
        width: 65%;
      }
    }
    .value {
      text-align: right;
    }
  }
}

.purchase-summary-card-skeleton {
  height: 441px;
}
