@import "src/styles/variables";

%title-font {
    font-family: "Noto Sans";
    font-style: normal;
}

%title-heading {
    color: var(--Black-Base, #000);
    font-weight: 600;
    line-height: 26px; /* 162.5% */
}

%text-body {
    font-size: 14px;
    line-height: 24px; /* 171.429% */
}

.title {
    @extend %title-font;
    @extend %title-heading;
    font-size: 16px;
}

.emptystatecomponent {
    height: 16em;
    flex-shrink: 0;
    border-radius: var(--Border-radius-XL, 20px);
    border: 1px solid $card-border;
    background: var(--White-Base, #FFF);
    margin: 16px 0 40px;

    &.no-border{
        border: none;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2.5em;
    }

    .header {
        @extend %title-font;
        @extend %text-body;
        @extend %title-heading;
        margin: 0;
        justify-content: center;
    }

    .description {
        @extend %title-font;
        @extend %text-body;
        color: var(--Grey-Base, $grey-base);
        text-align: center;
        font-weight: 400;
    }
}
