@import "src/styles/variables";
.color-picker-component__wrapper {
    margin-top: 32px;
    label {
        color: $label-color;
        display: inline-block;
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 13px;
    }
    .color-picker-component {
        cursor: pointer;
        border: 1px solid $primary-border-color;
        border-radius: 14px;
        background: #f7f9fc;
        display: flex;
        align-items: center;
        height: 50px;
        column-gap: 16px;
        padding-left: 7.5px;
        .selected-color {
            width: 35px;
            height: 35px;
            border-radius: 8px;
        }
    }
}
