@import "src/styles/variables";

.instore-view {
  .icon-trash {
    .path1:before,
    .path2:before,
    .path3:before,
    .path4:before {
      color: $red-base;
    }
  }
  .button-component{
    .ant-btn.ant-btn-primary{
      padding: 7px 20px 7px 18px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      i{
        font-size: 20px;
      }
      .text{
        color: #fff;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        text-align: center;
        font-family: Noto Sans;
      }
    }
  }
  .ant-dropdown-menu-title-content{
    color: $red-base;
  }
  .header {
    margin-bottom: 16px;
    .action-btn {
      text-align: end;
      i {
        cursor: pointer;
      }
    }
  }
  .card-component-content {
    padding: 31px 34px;
    height: 80vh;
  }
  .vendor-instore-details-modal {
    width: 100%;
  }
  .vendor-instore-terminals {
    h3 {
      font: normal normal 600 16px/23px Gilroy;
      margin-bottom: 24px;
    }
    .terminal-id-row {
      font: normal normal 500 15px/28px Gilroy;
      .label{
        color: var(--Grey-Base, $grey-base);
        font-family: "Noto Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .terminal-col {
        .terminal-name, 
        .value {
          margin-bottom: 24px;
          color: #000;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          font-family: Noto Sans;
          line-height: 26px; 
        }
      }
      .status{
        font-family: "Noto Sans";
        font-size: 15px;
        font-style: normal;
        line-height: 26px;
        text-transform: capitalize;
        &.active {
          color: var(--Green-Base, $success-color);
        }
        &.inactive {
          color: var(--Red-Base, $red-base);
        }
      }
    }
  }
}
