@import "src/styles/variables";

.downpaymentconfiguration {
    .header{
        color: var(--Black-Base, #000);
        font-family: "Noto Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 173.333% */
    }
    .downpayment-card{
        margin-bottom: 16px;
       .card-component-content{
            display: flex;
            justify-content: flex-start;
        }
        .minimum-threshold, .maximum-threshold{
            .label{
                color: var(--Grey-Base, $grey-base);
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                text-transform: uppercase;
            }
            .value{
                color: var(--Black-Base, $basic-black);
                font-family: "Noto Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
            }
        }
        .minimum-threshold{
            flex: 0 0 30%;
        }
    }
}