@import "src/styles/variables";

.table-component {
    .table-data{
    padding: 12px 12px 4px 12px;
    border-radius: 20px !important;
    border: 1px solid $card-border;
    }
    .table-actions{
        margin-bottom: 23px;
    }
    .container{
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.25rem;
    }
    .title {
        font-family: Noto Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
    }
    &.empty {
        .ant-table-thead {
            display: none;
        }
    }
    &.filter,
    &.new {
        .title {
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
        }
        .table-actions {
            display: flex;
            column-gap: 16px;
            .button-component{
                margin-left: auto;
                .ant-btn.ant-btn-primary{
                    border-radius: 12px;
                }
            }
            .search-input {
                width: 50%;
            }
            .filter-dropdown-icon {
                background: #fff;
                border: 1px solid $grey-light-4;
                border-radius: 10px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                i {
                    font-size: 18px;
                }
            }
        }
    }
}
.table-filter-component {
    background: #ffffff;
    box-shadow: 0px 10px 40px #0d073933;
    border: 0.5px solid $primary-border-color;
    border-radius: 24px;
    padding: 32px 24px;
    width: 564px;
    .table-filter-component-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
        }
        .controllers {
            display: flex;
            column-gap: 32px;
            letter-spacing: 0.6px;
            font-weight: 600;
            .clear {
                color: $primary-red;
                cursor: pointer;
            }
            .apply {
                cursor: pointer;
                color: $input-border-color-focus;
            }
        }
    }
}
.table-loading__component {
    .ant-skeleton {
        padding: 1rem 0;
        width: 100%;
        height: 45px;
        display: block;
        .ant-skeleton-button {
            height: 15px;
            width: 100%;
        }
    }
}
.table-empty__component {
    padding: 20% 0;
    .empty__img {
        width: 56px;
        height: 56px;
    }
    .empty__text {
        font-weight: 500;
        margin-top: 24px;
    }
}
