@import "src/styles/variables";

.vendor-store-promotions {
    margin-top: 32px;
    .ant-col {
        max-width: fit-content;
    }
    .vendor-store-promotion__container {
        position: relative;
        border: 1px solid #e4e9f200;
        border-radius: 16px;
        width: 335px;
        height: 200px;
        .vendor-store-promotion-delete-spin {
            position: absolute;
            padding: 0;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }
        &:hover {
            .vendor-store-promotion-img {
                border-top-right-radius: 0;
            }
            .vendor-store-promotion-close {
                visibility: visible;
            }
        }
        &.is-deleting {
            .vendor-store-promotion-img {
                opacity: 0.5;
            }
            .vendor-store-promotion-close {
                visibility: hidden;
            }
            &:hover {
                .vendor-store-promotion-img {
                    border-top-right-radius: 16px;
                }
            }
        }
        .vendor-store-promotion-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: inherit;
        }
        .vendor-store-promotion-close {
            visibility: hidden;
            position: absolute;
            top: -6%;
            right: -3.5%;
            width: 24px;
            height: 24px;
            background-color: $primary-red;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .icon-close {
                &::before {
                    color: #fff;
                }
            }
        }
    }
}
