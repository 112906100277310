@import "src/styles/variables";

.vendor-configuration {
  .header{
    color: var(--Black-Base, #000);
    font-family: "Noto Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 173.333% */
    margin-bottom: 1em;
    &.card{
      margin-top: 2em;
    }
  }

  .label {
    letter-spacing: 1.2px;
    color: $primary-grey;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
  }

  .value {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 700;

    .icon-component {
      font-size: 14px;
      margin-left: 5px;
    }
  }
}

.configuration-container {
    margin-bottom: 15px;
}

.vendor-reorder-container{
  margin-top: 35px;
}