@import "src/styles/variables";

.vendor-info-card {
    height: 288px;
    overflow: scroll;

    .vendor-info-card-section {
        padding: 2em 4em;
        p {
            color: white;
            opacity: 0.6;
        }
        h2 {
            color: #fff;
            font-weight: 600;
        }
        .icon-edit {
            margin-left: 12px;
            .path1::before {
                color: transparent;
            }
            .path2::before {
                color: $primary-yellow;
            }
        }
    }

    .vendor-info-card-content {
        padding: 24px;
            .item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:not(:last-child){
                    margin-bottom: 16px;
                }
                .title, 
                .value {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px; 
                    font-family: Noto Sans;
                    font-style: normal;
                }
                .title {
                    color: $grey-base;
                }
                .value {
                    color: #000;
                }
                a.value {
                    text-decoration: underline;
                }
            }
        }
}
