@import "src/styles/variables";

.lst-container {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;

  .list {
    max-height: 500px;
    flex: 0 0 500px;
    overflow: auto;
  }

  .lst-btns-container {
    align-self: center;

    .lst-btn {
      margin-bottom: 10px;
    }
  }
}

.list-item {
  cursor: pointer;
  padding: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.selected-item {
  background-color: $blue-dark-1;
  color: white !important;
  border-radius: 8px; /* Rounded corners for selected items */
}

.save-vendor-order-btn {
  text-align: center;
  position: relative;
  right: 70px;
}
