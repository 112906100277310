@import "src/styles/variables";

.vendor-purchase-config {
    .vendor-purchase-config-action {
        visibility: hidden;
    }
    .ant-table-row {
        &:hover {
            .vendor-purchase-config-action {
                visibility: visible;
            }
        }
    }
}
