@import "src/styles/variables";

.north-start-kpi-component {
  .card-skeleton-component{
    min-height: 233px;
  }
  .content {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #43389a0f;
    border-radius: 26px;
    padding: 36px 40px;
    .bottom-content{
      display: flex;
      margin: auto;
      justify-content: space-between;
    }
    .independent-kpi {
      .label {
        font: normal normal normal 13px/20px Gilroy;
        letter-spacing: 0px;
        color: $primary-grey;
        margin-bottom: 12px;
      }
      .value {
        font: normal normal 600 20px/29px Gilroy;
        letter-spacing: 0.6px;
        color: $primary-black;
      }
      .first{
        margin-bottom: 38px;
      }
      .second{
        h3{
            margin-bottom: 0;
        }
      }
      .third{
        margin-bottom: 29px;
        margin-top: 25px;
      }
    }
    .total-fee-realized-kpi{
      background: transparent
        linear-gradient(90deg, #efedff 0%, #ffffff 50%, #ffffff 100%) 0% 0%
        no-repeat padding-box;
      border: 0.5px solid $dashboard-border;
    }
    .total-fee-outstanding-kpi{
      border: 0.5px solid $dashboard-border-yellow;
      background: transparent linear-gradient(90deg, #FFF6E5 0%, #FFFFFF 50%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
      .total-fee-collected{
        p.label{
          color: $label-color;
        }
      }
    }
    .total-fee-realized-kpi, .total-fee-outstanding-kpi  {
      border-radius: 20px;
      padding: 24px 32px;
      margin-top: 38px;
      flex-basis: 49%;
      &-row {
        height: 100%;
        align-items: center;
      }
      .total-fee-collected {
        .label {
          font: normal normal normal 13px/20px Gilroy;
          letter-spacing: 0px;
          color: $dashboard-total-fee-label-color;
          margin: 0;
          width: 62%;
          margin-bottom: 12px;
        }
        .value {
          letter-spacing: 0.96px;
          color: $primary-black;
          margin: 0;
          font: normal normal bold 20px/29px Gilroy;
        }
      }
      .total-fee-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child){
            margin-bottom: 15px;
        }
      }
      .label {
        font: normal normal 500 14px/24px Gilroy;
        letter-spacing: 0px;
        color: $primary-black;
      }
      .value {
        font: normal normal 600 14px/24px Gilroy;
        letter-spacing: 0px;
        color: $primary-black;
      }
    }
  }
}
