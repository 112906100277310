@import "src/styles/variables";

.purchase-customer-details-card {
  height: 390px;
  padding: 28px 24px;
  border-radius: 24px;
  border: 1px solid $card-border;
  background: #fff;

  .customer-details,
  .vendor-details {
    margin-bottom: 50px;
    &.details-row {
      .image__container {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50px;
        }
      }
      .details__container {
        .header {
          color: $blue-dark-1;
          font-family: Noto Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          margin-bottom: 6px;
          text-transform: uppercase;
        }
        .name {
          color: #000;
          font-family: Noto Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          &.customer{
            cursor: pointer;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        .key-values {
          &:not(:last-child) {
            margin-bottom: 12px;
          }
          i {
            font-size: 18px;
          }
          .key {
            color: $grey-base;
            font-family: Noto Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
          }
          .value {
            color: #000;
            font-family: Noto Sans;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 173.333% */
          }
        }
      }
    }
  }

  
}

.purchase-customer-details-card-skeleton {
  height: 362px;
}
