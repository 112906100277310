@import "src/styles/variables";

.modal-component {
    &.small {
        width: 392px !important;
    }
    &.medium {
        width: 466px !important;
    }
    &.large {
        width: 720px !important;
        padding: 30px;
        .modal-component-controllers {
            display: flex;
            justify-content: flex-end;
            column-gap: 16px;
            margin-top: 40px;
        }
    }
    &.larger {
        width: 816px !important;
        padding: 32px;
        .modal-component-controllers {
            display: flex;
            justify-content: flex-end;
            column-gap: 16px;
            margin-top: 40px;
        }
    }
    &.x-large {
        width: 1246px !important;
        padding: 30px;
        .ant-modal-content{
            .ant-modal-body{
                padding: 8px;
            }
        }
        .modal-component-controllers {
            display: flex;
            justify-content: flex-end;
            column-gap: 16px;
            margin-top: 40px;
        }
    }
    &.no-controllers {
        width: 376px !important;
        .no-controllers-modal-component-title {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
        }
    }
    &.no-controllers-large{
        width: 720px !important;
        padding: 30px;
    }
    &.details {
        width: 489px !important;
        .ant-modal-body {
            padding: 40px 32px 32px;
        }
    }
    &.alert,
    &.alert-large {
        width: 376px !important;

        .ant-modal-body {
            padding: 32px;
            .risepoint-card{
                .header{
                    display: flex;
                    justify-content: space-between;
                    .label{
                        color: $button-default-text-color;
                        font-family: "Noto Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 28px;
                    }
                }
                .terminal-id{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }
              }
        }
        .alert-modal-component-title {
            text-align: center;
            line-height: 1.576;
            h3 {
                padding: 0;
                font-weight: 700;
                line-height: 1.4;
            }
            p {
                margin-bottom: 0;
            }
        }
        .modal-component-controllers {
            margin-top: 32px;
            display: flex;
            justify-content: center;
            column-gap: 12px;
        }
    }
    &.alert-large {
        width: 450px !important;
        .modal-component-controllers {
            margin-top: 32px;
        }
    }
    .modal-component-title {
        letter-spacing: -0.4px;
        font-size: 20px;
        font-weight: 700;
    }
    .modal-component-description {
        color: var(--Grey-Base, $grey-base);
        font-family: "Noto Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }
    .ant-modal-content {
        box-shadow: 0px 10px 40px #43389a1a;
        border-radius: 24px;
    }
    .ant-modal-body {
        padding: 40px;
    }
    .ant-modal-close {
        background: #ffffff;
        box-shadow: 0 10px 40px $primary-box-shadow;
        border: 0.5px solid $primary-border-color;
        opacity: 1;
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
        border-radius: 16px;
        top: -10px;
        right: -10px;
        &:hover {
            background: $red-base;
            .ant-modal-close-x {
                .icon-close {
                    &::before {
                        color: #fff;
                    }
                }
            }
        }
        .ant-modal-close-x {
            width: 32px;
            height: 32px;
            line-height: 37px;
            .icon-close {
                &::before {
                    color: #7b839a;
                    font-size: 16px;
                }
            }
        }
    }
    .modal-component-controllers {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }
}

@media (max-width: 480px) {
    .modal-component {
        .modal-component-title {
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
        }
    }
  }