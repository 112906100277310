@import "src/styles/variables";

.navbar__container {
    width: 12.5%;
    height: 100vh;
    overflow-y: auto;
    background: linear-gradient(178deg, #EEFEF3 -1.76%, #E5F9F1 27.52%, #D7F5F6 66.33%, #E8F1F4 100%) !important;
    padding-top: 3em !important;
    position: sticky;
    top: 0;

    .navbar-app-logo__img {
        width: 100px;
        height: 48.113px;
        margin-bottom: 40px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
.navbar-footer {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 12.5%;
    padding: 11px 0;
    background: $checkbox-bg-color 0% 0% no-repeat padding-box;
    opacity: 1;
    p {
        text-align: center;
        font: normal normal medium 12px/24px Gilroy;
        letter-spacing: 0;
        color: $text-grey;
        opacity: 1;
        margin: 0;
    }
}
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.navbar {
    background: transparent;
    border: none;
    &:first-child {
        text-align: center;
    }

    .ant-menu-item.ant-menu-item-only-child.navbar-item.disabled {
        cursor: not-allowed;
        &.ant-menu-item{
            &::before {
                background: $disabled-menu-color;
            }
        }
        &,
        &:hover {
            .navbar-item__title {
                border-radius: 0;
                background: $disabled-menu-color;
            }
        }
    }
    .ant-menu-item.ant-menu-item-only-child.navbar-item {
        color: $menu-title !important;
        font-weight: 500;
        text-align: left;
        height: 50px;
        line-height: 50px;
        margin-top: 0;
        background: none;
        padding: 0 10px;
        padding-left: 10px !important;
        &:hover,
        &.ant-menu-item-selected {
            .navbar-item__title {
                border: 1px solid $menu-title-selected;
                background: linear-gradient(289deg, rgba(117, 120, 123, 0.20) 3.87%, rgba(174, 176, 178, 0.20) 100%) !important;
                backdrop-filter: blur(20px);
                border-radius: 16px !important;
                color: $grey-dark-4 !important;
            }

            &::before {
                width: 4px;
                height: 38px;
                border-radius: 20px;
                content: " ";
                margin: 0;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0%, -50%);
            }
            &::after{
                display: none;
            }
        }
        .navbar-item__title {
            padding-left: 1em;
            display: flex;
            align-items: center;
            column-gap: 10px;
            .icon-component {
                font-size: 20px;
                [class^="path"] {
                    &::before {
                        color: $secondary-border-color;
                    }
                }
            }
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:active {
            background: none;
        }
        &.ant-menu-item-selected {
            .navbar-item__title {
                color: $grey-dark-4 !important;
                font-weight: 600;
                .icon-component {
                    font-size: 20px;
                    [class^="path"] {
                        &::before {
                            color: $grey-dark-4 !important;
                        }
                    }
                }
            }
        }
    }

    .ant-menu-submenu.ant-menu-submenu-inline.navbar-sub-item.disabled {
        &::before{
            background: $disabled-menu-color;
        }
        &:hover {
            .ant-menu-submenu-title {
                border-radius: 0;
                cursor: not-allowed;
                background: $disabled-menu-color;
            }
        }
    }
    .navbar-sub-item{
        color: $menu-title;
        font-weight: 500;
        text-align: left;
        line-height: 50px;
        margin-top: 0;
        padding: 0 10px;
        position: relative;
        .ant-menu-item.ant-menu-item-only-child.navbar-item{
            padding-left: 1em !important;
            height: 30px;
        }
        .ant-menu.ant-menu-sub.ant-menu-inline{
            border: 1px solid $menu-title-selected;
            background: linear-gradient(289deg, rgba(117, 120, 123, 0.20) 3.87%, rgba(174, 176, 178, 0.20) 100%) !important;
            border-radius: 0 0 10px 10px;
            padding: 10px 0;
            .ant-menu-item.navbar-item {
                padding: 1em;
                .navbar-item__title {
                    background:transparent;
                }
                &::before {
                    display: none;
                }
                &::after{
                    display: none;
                }
                .ant-menu-title-content{
                    .navbar-item__title{
                        background: transparent !important;
                    }
                }
            }
        }
        .ant-menu-submenu.ant-menu-submenu-inlin.navbar-sub-item.ant-menu-submenu-selected{
            position: relative;
        }
        .ant-menu-submenu-active{
            color: #fff !important;
        }
        &:hover,
        &.ant-menu-submenu-selected {
            .ant-menu-submenu-title {
                border: 1px solid $menu-title-selected;
                background: linear-gradient(289deg, rgba(117, 120, 123, 0.20) 3.87%, rgba(174, 176, 178, 0.20) 100%) !important;
                backdrop-filter: blur(20px);
                border-radius: 16px !important;
                color: $grey-dark-4 !important;
            }
            &::before {
                width: 4px;
                height: 38px;
                background: linear-gradient(289deg, rgba(117, 120, 123, 0.20) 3.87%, rgba(174, 176, 178, 0.20) 100%) !important;
                border-radius: 0px 20px 20px 0px;
                content: " ";
                margin: 0;
                position: absolute;
                top: 6px;
                left: 0;
            }
            &::after{
                display: none;
            }
        }

        .ant-menu-submenu-title{
            padding-left: 1em !important;
            display: flex;
            align-items: center;
            column-gap: 10px;
            height: 50px !important;
            border-radius: 10px;
            margin-bottom: 0;

            .ant-menu-submenu-arrow{
                display: none;
            }
            .icon-component {
                font-size: 20px;
                [class^="path"] {
                    &::before {
                        color: $secondary-border-color;
                    }
                }
            }
        }
        .navbar-item__title {
            padding-left: 0 !important;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:active {
            background: none;
        }
        &.ant-menu-submenu-selected {
            .ant-menu-submenu-title {
                border-radius: 16px 16px 0 0 !important;
                color: #ffffff;
                font-weight: 600;
                .icon-component {
                    font-size: 20px;
                    [class^="path"] {
                        &::before {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content{
    text-overflow: clip !important;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.navbar .navbar-sub-item .ant-menu-submenu-title {
    column-gap: 3px !important; 
}
