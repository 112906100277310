@import "src/styles/variables";

.vendor-details-card {
    border-radius: 20px;
    height: 288px;
    border: 1px solid $card-border;
    background: $purchase-card-bg;
    color: #fff;
    padding: 24px 32px;

    .item{
        .title{
            margin-bottom: 4px;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; 
            font-family: Noto Sans;
            opacity: 0.7;
        }
        .value{
            margin-bottom: 32px;
            color: #FFF;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            font-family: Noto Sans;
            .icon-edit {
                margin-left: 12px;
                .path1::before {
                    color: transparent;
                }
                .path2::before {
                    color: #FFF;
                }
            }
            .reset{
                font-size: 15px;
                cursor: pointer;
                margin-left: 4px;
            }
        }
    }
    
}
