@import "src/styles/variables";

.menu-component {
    &.primary {
        .icon-more {
            &::before {
                color: $primary-grey;
            }
        }
        .icon-dropdown-arrow {
            &::before {
                color:  $button-default-bg-color;
            }
        }
    }
    &.secondary {
        .icon-more {
            &::before {
                color: $primary-yellow;
            }
        }
    }
}

.menu-component-overlay {
    padding: 16px 16px 0 !important;
    border-radius: 16px !important;
    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu {
        padding: 0;
        letter-spacing: 0px;
        letter-spacing: 0.8px;
        padding-bottom: 16px;
        font-weight: 500;
        &:hover {
            background: unset;
        }
        i, .anticon {
            margin-right: 15px;
            font-size: 20px;
            letter-spacing: 0px;
        }
        .ant-dropdown-menu-title-content{
            white-space: pre;
            .subtitle{
                .title{
                    color: var(--Grey-Base, $grey-base);
                    font-size: 12px;
                    font-family: "Noto Sans";
                    font-weight: 400;
                }
                .description{  
                    font-size: 12px;
                    font-family: "Noto Sans";
                    font-weight: 400;
                    &.approve{
                        color: var(--Green-Base, $green-base); 
                    }
                    &.decline{
                        color: var(--Red-Base, $red-base);
                    }
                }
            }
        }
        .ant-dropdown-menu-submenu-title{
            padding: 0;
            font-weight: 500;
            background: none;
        }
        .ant-dropdown-menu-submenu-expand-icon{
            display: none;
        }
        &.ant-dropdown-menu-submenu-open{
            background: none;
        }
    }
}
