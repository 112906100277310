@import "src/styles/variables";

.approve-customers-mobile{
    .pending-approval-list-card{
        cursor: pointer;
        margin-top: 24px;
        border-radius: 20px;
        border: 1px solid $card-border;
        padding: 24px;
        .reapply-badge{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: -25px;
            margin-right: -25px;
            gap: 10px;
            .badge-component.reapply.border.reapply{
                border-radius: 16px 16px 0px 16px !important;
            }
        }
        .customer-name{
            font: normal normal 600 16px/23px Gilroy;
            letter-spacing: 0.6px;
            color: $primary-black;
        }
        .customer-details-1{
            margin-top: 17px;
            text-overflow: ellipsis !important;
            overflow: hidden;
            .phone-number{
                font: normal normal 14px/20px Gilroy;
                white-space: nowrap;        
                text-overflow: ellipsis;
                margin-right: 14px;
            }
            .email {
                font: normal normal 14px/20px Gilroy;
                white-space: nowrap;     
                text-overflow: ellipsis !important;
            }
            .icon-phone,
            .icon-email{
                margin-top: 17px;
                margin-right: 3px;
                .path2::before {
                    margin-top: 17px;
                    color: $icon-blue;
                }
            }
        }
        .customer-details-2{
            margin-top: 18px;
            .label{
                font: normal normal normal 12px/24px Gilroy;
                color: $primary-grey;
                margin-top: 16px;
                white-space: nowrap;   
                overflow: hidden;      
                text-overflow: ellipsis;
                margin-right: 12px;
            }
            .onboard-date{
                margin-top: 16px;
                font: normal normal 13px/19px Gilroy;
                word-break: break-word;
            }
            .kyc-status{
                margin-top: 16px;
                margin-left: 75px;
                font: normal normal 13px/19px Gilroy;
                word-break: break-word;
            }
        }
    }
}