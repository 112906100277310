@import "src/styles/variables";

.settlement-info {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: -15px;
  &-container {
    background: $button-affirmative-text-color 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px $dashboard-box-shadow;
    border-radius: 20px;
    padding: 32px;
    width: 70%;
    h3 {
      margin-bottom: 32px;
      font: normal normal 500 12px/16px Gilroy;
      letter-spacing: 1.2px;
      color: $secondary-grey;
    }
    .content {
      p {
        margin: 0;
      }
      > :not(:last-child) {
        margin-bottom: 24px;
      }
      .number {
        font: normal normal 600 16px/30px Gilroy;
        letter-spacing: 0px;
        color: $primary-grey;
        background: rgba(228, 233, 242, .5) 0% 0% no-repeat padding-box;
        border-radius: 50%;
        text-align: center;
        height: 28px;
        width: 28px;
      }
      .text {
        font: normal normal 500 15px/24px Gilroy;
        letter-spacing: 0px;
        color: $primary-black;
        margin-top: 3px;
      }
    }
  }
}
