@import "src/styles/variables";

.profile-details {
    display: inline;
    .icon-user-profile {
        [class^="path"] {
            &::before {
                color: $checkbox-bg-color;
            }
        }
    }
}

.profile-details__overlay {
    background: #ffffff;
    box-shadow: 0px 0px 40px #0f0c2c29;
    border-radius: 20px;
    width: 346px;
    padding: 32px 0;
    line-height: 1;
    .profile-details-name__container {
        padding: 0 32px 26px;
        .icon-user-profile1{
            font-size: 64px;
        }
        .name {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        .role {
            color: $primary-grey;
            font-size: 13px;
            margin-bottom: 0;
        }
    }
    .profile-details-contact__container {
        padding: 0 32px;
        p {
            font-weight: 500;
            display: flex;
            align-items: center;
            &:first-child {
                margin-bottom: 20px;
            }
        }
        .icon-component {
            font-size: 16px;
            margin-right: 8px;
        }
    }
    .profile-details-options__container {
        font-weight: 600;
        letter-spacing: 0.75px;
        padding: 0 32px;
        .icon-component {
            font-size: 16px;
            margin-right: 8px;
        }
        div {
            display: flex;
            align-items: center;
        }
        .edit-profile {
            margin-top: 32px;
            cursor: pointer;
        }
        .change-password {
            margin-top: 32px;
            cursor: pointer;
        }
        .two-factor-authentication {
            margin-top: 32px;
            cursor: pointer;
        }
        .logout {
            margin-top: 32px;
            color: #c03445;
            cursor: pointer;

            i::before {
                color: #c03445;
            }
        }
    }
}
.icon-guard {
    width: 16.75px;
    height: 16px;
}