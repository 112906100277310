@import "src/styles/variables";

.instore-form-view {
  margin: 24px 25px;
  .vendor-instore-form,
  .vendor-terminal-id-container {
    width: 75%;
  }
  .terminal-section {
    margin-top: 23px;
  }
  .input-field{
    margin-top: 20px;
  }
  .vendor-terminal-id-container {
    h3 {
      font: normal normal 600 16px/23px Noto Sans;
      letter-spacing: 0;
      color: $primary-black;
    }
    p {
      font: normal normal medium 14px/24px Noto Sans;
      letter-spacing: 0;
      color: $primary-grey;
    }
    .add-btn{
      padding-left: 14px;
      margin-top: 24px;
      margin-bottom: 0;
      color: $blue-dark-1;
      font: normal normal 600 15px/21px Noto Sans;
      letter-spacing: 0.6px;
      cursor: pointer;
      width: fit-content;
    }
    .minus-icon{
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .ant-table{
    margin-bottom: 16px;
  }
  .input-row{
    &.disabled{
      .input-field{
        input{
          pointer-events: none;
          height: 35px;
        }
      }
    }
    .input__error{
      padding-left: 16px;
    }
    .input-field{
      padding-left: 16px;
      input{
        font: normal normal 500 15px/48px Noto Sans;
        letter-spacing: 0;
        color: $primary-black;
        border: none;
        background: none;
        border-radius: 0;
        padding: 0;
        border-bottom: 1px solid transparent;
        &::placeholder{
          font: normal normal 500 15px/21px Noto Sans;
          letter-spacing: 0;
          color: $secondary-border-color;
        }
        &:hover{
          border-bottom: 1px solid $primary-grey;
        }
      }
    }
    .remove-icon{
      margin-top: 15px;
      .icon-minus{
        cursor: pointer;
        font-size: 20px;
        padding-top: 10px;
        .path1,.path2{
          &::before{
            color: $primary-red;
          }
        }
      }
    }
  }
  
  .header {
    margin: 0;
    padding: 0;
    .title {
      font: normal normal 800 20px/48px Noto Sans;
      letter-spacing: -0.4px;
      color: $primary-black;
      i{
        margin-right: 10px;
      }
      .text{
        color: #000;
        font-family: Noto Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }
    }
    .controllers {
      display: flex;
      .button-component {
        &:last-child {
          margin-left: 12px;
        }
      }
    }
  }
}
