@import "src/styles/variables";

.vendor-form {
    .password-field input[type="text"] {
        -webkit-text-security: disc;
      }
    .cover-picture + .input__error{
        text-align: center;
    }
    .vendor-form-details__container {
        margin-top: -20px;
        padding-left: 40px;
        padding-right: 40px;
        .vendor-display-picture-name__container {
            display: flex;
            gap: 18px;
            .vendor-name__input {
                margin-top: 28px;
                input {
                    border: none !important;
                    background: none !important;
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: -0.4px;
                    padding: 0 18px;
                }
                .input__error{
                    margin-left: 18px;
                }
            }
        }
        .ant-divider {
            margin: 32px 0 0;
        }
        h4 {
            margin: 32px 0 0;
            font-weight: 600;
        }
    }
}
.vendor-form-modal {
    .ant-modal-body {
        padding: 0 0 40px 0;
    }
    .modal-component-controllers {
        padding-right: 40px;
    }
}
