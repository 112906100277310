@import "src/styles/variables";

.line-chart-component {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 40px $doughnut-box-shadow;
  border-radius: 26px;
  padding: 32px 24px;
  &.small{
    height: 400px;
  }
  .transaction-value-kpi__skeleton{
    box-shadow: none;
  }
}
