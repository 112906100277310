@import "src/styles/variables";

.ellipsis {
  display: inline-block;
  max-width: 200px; /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-button {
  background: none;
  border: none;
  color: blue;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
}

.link-button:hover {
  text-decoration: none;
}
