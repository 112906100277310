@import "src/styles/variables";

.overdue-payments {
    .due-on__container {
        display: flex;
        column-gap: 10.65px;
        .badge-component {
            margin-top: -3px;
        }
    }
}