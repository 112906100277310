@import "src/styles/variables";

.outstanding-payments, .payment-adjustment-content {
    // background: #FFFFFF;
    // box-shadow: 0px 0px 40px #43389A0F;
    // border-radius: 20px;
    // padding: 16px;
    .due-on__container {
        display: flex;
        column-gap: 10.65px;
        .badge-component {
            margin-top: -3px;
        }
    }
}