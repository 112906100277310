@import "src/styles/variables";


label {
    display: inline-block;
    margin-bottom: 5px;
    letter-spacing: 0;
    color: $label-color;
    color: $grey-base;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.input-field {
    input {
        &::placeholder {
          letter-spacing: 0;
          color: $grey-light-2;
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
        }
    }
    margin-top: 22px;
    &.error {
        input {
            border: 1px solid $input-border-error-color !important;
        }
        .ant-input-affix-wrapper{
            border: 1px solid $input-border-error-color !important;
            input.ant-input{
                border: 1px solid transparent !important;
            }
        }
    }

    textarea.ant-input {
        padding: 10px;
    }
}
